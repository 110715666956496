import React from "react"

const Step2 = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="35.5" stroke="url(#step_2_circle)" />
    <path
      d="M43.007 47H29.209V44.0973L34.1639 39.0884C35.631 37.5853 36.5895 36.5457 37.0396 35.9697C37.4896 35.3847 37.8136 34.8446 38.0116 34.3496C38.2097 33.8545 38.3087 33.3415 38.3087 32.8105C38.3087 32.0184 38.0882 31.4289 37.6471 31.0418C37.2151 30.6548 36.6345 30.4613 35.9055 30.4613C35.1404 30.4613 34.3979 30.6368 33.6778 30.9878C32.9578 31.3389 32.2062 31.8384 31.4232 32.4865L29.155 29.7998C30.1271 28.9717 30.9326 28.3867 31.5717 28.0446C32.2107 27.7026 32.9083 27.4416 33.6643 27.2616C34.4204 27.0726 35.2664 26.9781 36.2025 26.9781C37.4356 26.9781 38.5247 27.2031 39.4698 27.6531C40.4148 28.1031 41.1484 28.7332 41.6704 29.5432C42.1924 30.3533 42.4535 31.2804 42.4535 32.3244C42.4535 33.2335 42.2915 34.0886 41.9674 34.8896C41.6524 35.6817 41.1574 36.4962 40.4823 37.3333C39.8163 38.1704 38.6372 39.3629 36.9451 40.9111L34.4069 43.3007V43.4897H43.007V47Z"
      fill="url(#step_2_number)"
    />
    <defs>
      <linearGradient
        id="step_2_circle"
        x1="36"
        y1="0"
        x2="36"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="step_2_number"
        x1="36"
        y1="20"
        x2="36"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </svg>
)

export default Step2
