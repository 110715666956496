import React from "react"

const Step1 = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="35.5" stroke="url(#step_1_circle)" />
    <path
      d="M39.5238 47H35.352V35.5782L35.3925 33.7015L35.46 31.6494C34.7669 32.3424 34.2854 32.797 34.0154 33.013L31.7472 34.8356L29.7355 32.3244L36.0945 27.2616H39.5238V47Z"
      fill="url(#step_1_number)"
    />
    <defs>
      <linearGradient
        id="step_1_circle"
        x1="36"
        y1="0"
        x2="36"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="step_1_number"
        x1="36"
        y1="20"
        x2="36"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </svg>
)

export default Step1
