// @ts-nocheck
import React from "react"

const Feature1 = () => (
  <svg
    width="238"
    height="131"
    viewBox="0 0 238 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M207.56 17.1264C174.193 -7.25203 152.142 -1.55394 133.878 9.38112C120.143 17.6061 109.372 34.5258 76.3589 38.9907C20.773 46.5079 -17.3434 79.8882 8.00776 129.895H186.878C189.413 120.85 192.856 113.3 201.6 107.91C244.413 81.5192 240.925 41.5039 207.56 17.1264Z"
      fill="#F8F8F8"
    />
    <path
      d="M192.304 38.1527C194.157 43.1147 197.868 44.9553 203.146 44.8305C207.135 40.8228 207.766 36.0372 204.538 31.1765C201.324 26.3578 195.331 22.4768 190.218 21.229C189.996 26.2983 190.469 33.2452 192.304 38.1527Z"
      fill="#BADBCA"
    />
    <path
      d="M191.79 23.4901C196.248 29.6639 201.171 39.2585 204.231 47.036C205.252 49.6281 214.586 79.1656 200.331 96.9978"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M146.075 56.3055H143.089V72.8211H146.075V56.3055Z"
      fill="#9AC7F7"
    />
    <path
      d="M204.455 81.1211C204.455 81.1211 197.487 65.9544 203.877 56.9964C210.266 48.0383 222.911 44.3462 222.911 44.3462C222.911 44.3462 230.377 62.218 222.305 71.1007C214.234 79.9814 206.5 78.9996 204.455 81.1211Z"
      fill="#B8F9D8"
    />
    <path d="M146.333 64.4514H144.248V78.715H146.333V64.4514Z" fill="#7FE0AF" />
    <path
      d="M149.581 59.4725H147.496V73.7361H149.581V59.4725Z"
      fill="#7FE0AF"
    />
    <path d="M153.754 53.3196H151.67V59.4263H153.754V53.3196Z" fill="#7FE0AF" />
    <path d="M168.365 66.5168H166.28V72.6234H168.365V66.5168Z" fill="#7FE0AF" />
    <path
      d="M157.232 50.3918H155.147V56.4985H157.232V50.3918Z"
      fill="#7FE0AF"
    />
    <path d="M160.248 53.74H158.163V73.6365H160.248V53.74Z" fill="#C8F0B9" />
    <path
      d="M142.855 60.1392H140.771V75.1948H142.855V60.1392Z"
      fill="#C8F0B9"
    />
    <path
      d="M139.608 57.5448H137.523V67.8891H139.608V57.5448Z"
      fill="#C8F0B9"
    />
    <path d="M138.727 55.5663H138.45V75.7728H138.727V55.5663Z" fill="#C8F0B9" />
    <path d="M132.882 58.871H130.798V69.2152H132.882V58.871Z" fill="#C8F0B9" />
    <path
      d="M132.002 56.8925H131.726V77.0989H132.002V56.8925Z"
      fill="#C8F0B9"
    />
    <path
      d="M129.635 63.9586H127.551V74.3028H129.635V63.9586Z"
      fill="#C8F0B9"
    />
    <path d="M128.755 61.98H128.479V75.1891H128.755V61.98Z" fill="#C8F0B9" />
    <path
      d="M141.974 61.5525H141.697V81.7589H141.974V61.5525Z"
      fill="#C8F0B9"
    />
    <path
      d="M159.367 58.7043H159.091V78.9108H159.367V58.7043Z"
      fill="#C8F0B9"
    />
    <path
      d="M163.078 63.8788H162.802V78.3325H163.078V63.8788Z"
      fill="#C8F0B9"
    />
    <path
      d="M166.093 74.6918H165.816V85.0548H166.093V74.6918Z"
      fill="#C8F0B9"
    />
    <path
      d="M145.452 60.5451H145.176V75.8098H145.452V60.5451Z"
      fill="#7FE0AF"
    />
    <path
      d="M136.129 55.4069H134.045V69.6705H136.129V55.4069Z"
      fill="#7FE0AF"
    />
    <path
      d="M135.016 51.5006H134.739V66.7653H135.016V51.5006Z"
      fill="#7FE0AF"
    />
    <path
      d="M148.699 62.9222H148.423V78.1869H148.699V62.9222Z"
      fill="#7FE0AF"
    />
    <path d="M167.484 65.966H167.208V72.583H167.484V65.966Z" fill="#7FE0AF" />
    <path
      d="M152.874 53.8849H152.598V61.4786H152.874V53.8849Z"
      fill="#7FE0AF"
    />
    <path
      d="M156.353 49.4351H156.076V57.0288H156.353V49.4351Z"
      fill="#7FE0AF"
    />
    <path d="M164.19 68.9809H162.105V76.4059H164.19V68.9809Z" fill="#C8F0B9" />
    <path
      d="M166.973 74.1917H164.889V81.6166H166.973V74.1917Z"
      fill="#C8F0B9"
    />
    <path
      d="M95.5644 50.2491C95.5644 50.2491 108.98 52.929 113.551 51.3127C118.121 49.6954 111.989 46.9404 113.958 40.9109C115.927 34.8814 118.651 18.4627 111.778 25.7381C104.906 33.0126 107.825 38.1559 109.379 33.9065C110.932 29.6561 117.917 19.5117 105.927 15.8091C93.9383 12.1066 87.1074 18.3057 95.4805 25.8181C103.854 33.3304 87.7489 10.7866 85.3488 18.955C82.9486 27.1234 90.1617 28.8431 92.5569 29.6551C94.9521 30.4672 85.3068 20.0313 83.5706 28.9435C81.8346 37.8567 87.4808 44.1845 95.5644 50.2491Z"
      fill="#212A54"
    />
    <path
      d="M166.977 63.5092C166.977 63.5092 171.243 64.0454 182.737 72.2537L166.977 63.5092Z"
      fill="#C5E1F9"
    />
    <path
      d="M174.006 69.7408C173.344 69.4893 172.736 69.2193 172.179 68.9366C171.9 68.7952 171.634 68.65 171.38 68.5028C171.125 68.3653 170.88 68.2366 170.649 68.0972C170.186 67.8242 169.769 67.5347 169.397 67.2471C169.029 66.9868 168.702 66.7158 168.418 66.438C168.128 66.1484 167.902 65.9164 167.7 65.6523C167.602 65.5236 167.511 65.3929 167.431 65.2623C167.35 65.1327 167.292 65.0225 167.233 64.8997C167.177 64.7788 167.127 64.6579 167.086 64.536C167.067 64.4766 167.048 64.4142 167.032 64.3557C167.021 64.2962 167.016 64.2416 167.008 64.1841C166.978 63.956 166.959 63.7249 167 63.5066C167.026 63.2843 167.106 63.0728 167.288 62.8846C167.385 62.791 167.47 62.7062 167.585 62.6263C167.698 62.5473 167.827 62.4742 167.974 62.4099C168.28 62.2743 168.601 62.1934 168.989 62.1408C169.182 62.1135 169.389 62.0979 169.608 62.0911C169.83 62.0823 170.036 62.0998 170.268 62.1184C170.723 62.1613 171.227 62.2412 171.754 62.3718C172.262 62.5181 172.799 62.7101 173.358 62.947C173.637 63.0669 173.931 63.1917 174.212 63.3428C174.49 63.4949 174.772 63.6606 175.057 63.837C175.627 64.1919 176.213 64.5974 176.801 65.0507C177.278 65.428 177.818 65.9388 178.383 66.514C178.665 66.8016 178.952 67.1077 179.239 67.4246C179.525 67.7394 179.815 68.0621 180.08 68.4111C181.163 69.7798 182.157 71.206 182.763 72.2511C181.551 72.0542 179.978 71.7022 178.412 71.245C178.215 71.1856 178.02 71.1271 177.825 71.0686C177.627 71.013 177.428 70.9565 177.233 70.9019C176.841 70.7907 176.454 70.6718 176.08 70.548C175.329 70.3024 174.626 70.0284 174.006 69.7408Z"
      fill="#A1BFB0"
    />
    <path
      d="M171.847 75.4309C171.847 75.4309 185.328 75.9954 185.883 76.0578L171.847 75.4309Z"
      fill="#C5E1F9"
    />
    <path
      d="M178.926 78.4656C174.399 79.1217 171.694 77.2119 171.853 75.4318C171.913 73.6459 174.802 71.9896 179.182 73.0122C180.107 73.1965 181.396 73.681 182.641 74.2571C183.89 74.8333 185.093 75.501 185.887 76.0577C184.976 76.5383 183.76 77.0813 182.515 77.5346C181.27 77.9879 179.992 78.3506 178.926 78.4656Z"
      fill="#A1BFB0"
    />
    <path
      d="M182.63 70.9722C180.477 67.3565 181.345 64.3831 183.055 63.8665C184.738 63.265 187.262 65.1221 187.789 69.1824C188.064 70.8387 187.726 74.1591 187.255 75.9713C185.755 74.8454 183.595 72.7416 182.63 70.9722Z"
      fill="#A1BFB0"
    />
    <path
      d="M190.631 63.1541C190.631 63.1541 195.033 71.9679 191.012 80.225L190.631 63.1541Z"
      fill="#C5E1F9"
    />
    <path
      d="M189.963 71.9938C189.831 71.3806 189.718 70.8055 189.622 70.2586C189.518 69.7117 189.442 69.1931 189.378 68.7037C189.253 67.723 189.211 66.8485 189.258 66.0901C189.35 64.5751 189.808 63.5164 190.629 63.1577C191.419 62.7443 192.672 63.2327 193.75 64.7009C194.282 65.434 194.775 66.4098 195.094 67.6128C195.419 68.8119 195.571 70.243 195.42 71.8106C195.362 72.4569 195.232 73.2358 194.981 74.0566C194.732 74.8775 194.397 75.7373 193.967 76.5406C193.536 77.3458 193.048 78.0965 192.523 78.7224C192.398 78.8813 192.264 79.0304 192.132 79.1708C192 79.3121 191.872 79.4467 191.748 79.5734C191.487 79.8239 191.236 80.0413 191.009 80.2295C191.005 79.9302 191.001 79.6105 190.996 79.279C190.977 78.9417 190.975 78.5976 190.947 78.2408C190.904 77.5291 190.82 76.7843 190.719 76.0532C190.607 75.3181 190.475 74.5977 190.341 73.9133C190.2 73.229 190.061 72.5827 189.963 71.9938Z"
      fill="#A1BFB0"
    />
    <path
      d="M176.021 82.3111C176.021 82.3111 183.967 80.6217 190.947 82.0255L176.021 82.3111Z"
      fill="#C5E1F9"
    />
    <path
      d="M183.778 84.2124C183.216 84.3245 182.682 84.4278 182.176 84.4805C181.669 84.5497 181.19 84.5702 180.739 84.5809C179.836 84.5858 179.045 84.4912 178.378 84.2982C177.045 83.9219 176.206 83.1878 176.058 82.3065C175.861 81.436 176.461 80.3968 177.777 79.6344C178.433 79.2611 179.266 78.9452 180.254 78.7804C180.747 78.6878 181.281 78.6508 181.845 78.6342C182.413 78.6362 183.009 78.6576 183.637 78.7531C184.668 78.8565 186.137 79.2991 187.52 79.9123C187.866 80.0712 188.206 80.2291 188.536 80.3997C188.863 80.5791 189.18 80.7604 189.476 80.9388C189.775 81.1114 190.051 81.3112 190.304 81.4935C190.556 81.6778 190.784 81.8542 190.984 82.0219C190.732 82.1106 190.465 82.2022 190.184 82.2929C189.902 82.3884 189.613 82.4674 189.309 82.5775C188.695 82.7803 188.054 82.9909 187.427 83.1976C187.267 83.2473 187.108 83.298 186.949 83.3477C186.788 83.3994 186.629 83.451 186.47 83.5027C186.153 83.606 185.84 83.6928 185.531 83.7835C185.222 83.8761 184.919 83.958 184.628 84.0272C184.335 84.0935 184.05 84.1666 183.778 84.2124Z"
      fill="#A1BFB0"
    />
    <path
      d="M176.716 88.5069C176.716 88.5069 181.383 84.9857 193.747 90.482L176.716 88.5069Z"
      fill="#C5E1F9"
    />
    <path
      d="M185.053 90.4792C184.732 90.4782 184.421 90.4685 184.121 90.45C184.047 90.4451 183.972 90.4392 183.899 90.4344C183.827 90.4344 183.755 90.4334 183.684 90.4334C183.54 90.4305 183.401 90.4256 183.263 90.4178C182.708 90.37 182.215 90.3671 181.743 90.3047C180.823 90.2374 180.051 90.1088 179.4 89.9616C178.755 89.8192 178.217 89.6262 177.761 89.3883C177.314 89.1563 176.963 88.86 176.702 88.4983C176.419 88.1512 176.406 87.6053 176.705 87.0175C177 86.4306 177.615 85.8262 178.509 85.3485C178.73 85.2345 178.97 85.1243 179.227 85.018C179.48 84.9244 179.747 84.8445 180.031 84.7665C180.172 84.7304 180.32 84.6846 180.466 84.6603C180.611 84.6339 180.761 84.6086 180.914 84.5871C181.067 84.5647 181.223 84.5442 181.383 84.5257C181.543 84.5072 181.706 84.4897 181.867 84.4887C182.19 84.4799 182.525 84.4809 182.871 84.4906C182.957 84.4936 183.042 84.4974 183.131 84.5013C183.215 84.5101 183.301 84.5199 183.386 84.5296C183.56 84.5501 183.735 84.5725 183.912 84.5979C184.088 84.6232 184.269 84.6525 184.449 84.6827C184.539 84.6992 184.63 84.7129 184.72 84.7324C184.809 84.7548 184.898 84.7782 184.987 84.8006C185.347 84.8932 185.711 84.9985 186.079 85.1145C186.389 85.1964 186.708 85.3358 187.051 85.484C187.393 85.6341 187.753 85.8038 188.12 85.988C188.481 86.1869 188.845 86.4092 189.212 86.6373C189.577 86.8713 189.951 87.0925 190.296 87.3636C190.647 87.6258 190.99 87.8919 191.32 88.1571C191.483 88.2906 191.645 88.4223 191.804 88.5539C191.962 88.6864 192.109 88.8297 192.256 88.9652C192.836 89.5131 193.342 90.0298 193.733 90.4734C193.13 90.5075 192.439 90.5231 191.705 90.5202C191.521 90.5182 191.335 90.5163 191.148 90.5143C190.957 90.5182 190.765 90.5221 190.573 90.526C190.185 90.5328 189.795 90.5338 189.405 90.5299C189.21 90.5289 189.017 90.5202 188.822 90.5221C188.626 90.526 188.432 90.5299 188.238 90.5338C187.853 90.5387 187.475 90.5358 187.106 90.5241C186.737 90.5172 186.378 90.5318 186.035 90.5202C185.692 90.5172 185.362 90.4782 185.053 90.4792Z"
      fill="#A1BFB0"
    />
    <path
      d="M199.524 71.2711C197.812 74.2541 195.037 89.6492 195.037 89.6492L199.524 71.2711Z"
      fill="#C5E1F9"
    />
    <path
      d="M194.205 80.0153C194.452 76.9064 195.306 74.4897 196.381 72.9378C196.916 72.1637 197.513 71.6032 198.083 71.3107C198.227 71.2366 198.363 71.183 198.498 71.145C198.639 71.1041 198.769 71.0845 198.894 71.0826C199.141 71.0816 199.355 71.1518 199.542 71.2756C199.737 71.3838 199.905 71.5456 200.053 71.7328C200.128 71.8284 200.196 71.9258 200.262 72.0341C200.328 72.1471 200.387 72.267 200.443 72.3928C200.665 72.9056 200.808 73.5743 200.846 74.4147C200.918 76.0895 200.572 78.4243 199.537 81.19C199.139 82.3364 198.372 83.9245 197.531 85.494C196.69 87.0636 195.774 88.6097 195.058 89.6499C194.434 87.199 193.881 83.0032 194.205 80.0153Z"
      fill="#A1BFB0"
    />
    <path
      d="M205.456 83.6493C205.456 83.6493 197.538 90.5699 194.109 99.0629L205.456 83.6493Z"
      fill="#C5E1F9"
    />
    <path
      d="M196.638 89.3402C196.737 89.1599 196.839 88.9825 196.941 88.8089C197.045 88.6374 197.154 88.4746 197.263 88.3108C197.478 87.9861 197.693 87.6723 197.914 87.3769C198.366 86.7988 198.81 86.2645 199.271 85.8112C200.177 84.889 201.094 84.229 201.918 83.7884C203.58 82.9139 204.911 82.9461 205.454 83.6558C206.051 84.3226 205.953 85.5899 205.2 87.1546C204.829 87.9442 204.281 88.7982 203.586 89.7175C203.228 90.1679 202.853 90.6495 202.412 91.1252C201.996 91.6156 201.506 92.0991 201.008 92.6129C200.176 93.46 198.9 94.585 197.605 95.7568C197.284 96.0493 196.959 96.3447 196.637 96.6371C196.331 96.9364 196.023 97.2298 195.719 97.5125C195.407 97.7913 195.142 98.078 194.872 98.3382C194.605 98.6024 194.35 98.8471 194.111 99.0665C194.107 98.7496 194.111 98.4026 194.123 98.0302C194.142 97.6617 194.149 97.2542 194.207 96.8516C194.258 96.446 194.319 96.02 194.39 95.5813C194.423 95.36 194.472 95.1465 194.521 94.9272L194.676 94.2643C194.733 94.041 194.79 93.8168 194.847 93.5945C194.909 93.3732 194.981 93.1568 195.053 92.9375C195.196 92.5027 195.341 92.0611 195.511 91.6438C195.682 91.2295 195.861 90.823 196.045 90.4321C196.228 90.0412 196.437 89.6844 196.638 89.3402Z"
      fill="#A1BFB0"
    />
    <path
      d="M176.485 93.7729C176.485 93.7729 184.74 91.8368 192.971 96.5854L176.485 93.7729Z"
      fill="#C5E1F9"
    />
    <path
      d="M184.755 96.4597C184.443 96.4646 184.144 96.4587 183.855 96.4451C183.562 96.4412 183.279 96.4382 183.007 96.4217C182.87 96.4129 182.736 96.4041 182.604 96.3954C182.471 96.3895 182.341 96.3827 182.212 96.3749C181.956 96.3583 181.708 96.3242 181.467 96.3008C180.507 96.1994 179.675 96.0308 178.973 95.8007C177.574 95.3484 176.685 94.6445 176.524 93.7652C176.412 93.3343 176.547 92.841 176.91 92.3731C177.274 91.9012 177.872 91.4557 178.687 91.1087C179.5 90.7597 180.531 90.5335 181.727 90.4857C182.027 90.4789 182.336 90.4877 182.655 90.5033C182.813 90.5111 182.973 90.5199 183.138 90.5335C183.3 90.554 183.466 90.5754 183.632 90.5998C183.967 90.6524 184.308 90.7051 184.654 90.786C185 90.8737 185.352 90.9722 185.708 91.0833C186.005 91.1603 186.323 91.29 186.66 91.4333C186.995 91.5805 187.352 91.7287 187.708 91.9266C188.065 92.1245 188.429 92.339 188.791 92.5632C188.973 92.6685 189.151 92.7952 189.326 92.9239C189.498 93.0516 189.673 93.1812 189.848 93.3109C190.016 93.4415 190.186 93.5722 190.356 93.7047C190.525 93.8324 190.68 93.9874 190.836 94.1278C191.145 94.4135 191.438 94.6962 191.712 94.9711C192.243 95.5492 192.679 96.1146 193.011 96.5786C192.42 96.564 191.756 96.526 191.062 96.4831C190.706 96.4802 190.342 96.4724 189.978 96.4626C189.796 96.4548 189.615 96.448 189.432 96.4421C189.247 96.4451 189.06 96.447 188.875 96.449C188.505 96.4529 188.139 96.447 187.778 96.4441C187.412 96.4597 187.054 96.4665 186.707 96.4665C186.363 96.4548 186.018 96.4811 185.691 96.4773C185.365 96.4811 185.057 96.4568 184.755 96.4597Z"
      fill="#A1BFB0"
    />
    <path
      d="M168.6 63.1469C168.6 63.1469 186.965 72.2394 192.164 83.9534C196.568 93.8736 192.914 96.961 192.045 106.043C191.93 107.252 190.832 113.796 196.027 122.188"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M183.674 63.9731C183.674 63.9731 185.691 74.0171 188.185 77.82"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M172.311 75.4467C172.311 75.4467 183.133 73.9864 187.742 76.9782"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M191.34 63.3279C191.34 63.3279 195.27 73.3252 190.631 80.9321"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M176.485 82.2482C176.485 82.2482 183.234 78.0456 191.251 82.0581"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M199.583 71.5681C199.583 71.5681 195.476 85.0798 194.342 94.4687"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M176.716 88.1439C179.923 85.114 193.093 87.6097 194.262 91.6934"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M204.644 84.1783C204.644 84.1783 193.601 95.0744 192.022 105.761"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M177.412 92.9903C177.412 92.9903 188.352 90.6623 193.653 97.4786"
      stroke="#9AC7F7"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M227.936 94.1352L230.661 96.279C233.027 94.8001 235.137 93.1457 236.913 91.391C235.541 89.375 233.895 87.4945 231.999 85.8352C222.662 77.6678 204.483 85.0358 203.386 101.283C208.4 103.089 215.579 102.785 223.079 99.9932C225.026 99.2679 226.88 98.4168 228.618 97.4673L227.936 94.1352Z"
      fill="#B8F9D8"
    />
    <path
      d="M237 91.389C237 91.389 217.81 83.8211 204.574 99.8722C204.202 100.323 200.518 105.229 199.443 111.928"
      stroke="#5B9F7D"
      strokeWidth="0.903627"
      strokeMiterlimit="2"
    />
    <path
      d="M233.283 87.0627L230.287 89.9055"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M230.268 84.5334L225.882 89.6018"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M226.416 82.9681L221.242 90.0096"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M221.447 82.5549L217.068 91.1055"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M216.889 83.5478L213.59 92.4814"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M211.875 86.0336L209.415 95.092"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M207.889 89.9109L206.168 98.1612"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M233.635 94.2194L230.52 89.8384"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M225.882 89.5992L231.155 96.0665"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M221.242 90.0051L227.061 98.2836"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M217.068 91.0994L222.743 100.179"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M213.59 92.4764L218.73 101.359"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M209.415 95.0927L213.895 102.239"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M206.168 98.1582L209.367 102.328"
      stroke="#5B9F7D"
      strokeWidth="0.271088"
      strokeMiterlimit="2"
    />
    <path
      d="M195.037 28.668C195.258 27.3207 195.393 25.7444 195.438 24.4322"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M197.124 32.2903C197.589 30.6769 198.141 29.0626 198.344 27.4833"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M199.211 36.133C200.289 33.536 201.043 30.6913 201.411 28.1645"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M200.835 39.2434C202.158 36.8355 203.146 34.3954 203.812 31.9982"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M202.227 42.3226C203.106 41.0563 203.885 39.7724 204.563 38.4846"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M194.805 27.98C193.492 27.5988 192.178 27.2137 190.862 26.8238"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M196.89 31.8791C194.877 31.378 193.113 30.8477 191.095 30.3242"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M198.818 35.5293C196.329 35.0194 194.053 34.4638 191.56 33.9188"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M200.57 38.8881C198.03 38.4826 195.492 38.0585 192.95 37.6149"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M202.09 42.0012C199.914 41.7516 197.675 41.4865 195.501 41.2096"
      stroke="#5B9F7D"
      strokeWidth="0.180725"
      strokeMiterlimit="2"
    />
    <path
      d="M222.354 45.1375C222.354 45.1375 197.229 77.7477 199.601 114.975"
      stroke="#5B9F7D"
      strokeWidth="0.903627"
      strokeMiterlimit="2"
    />
    <mask
      id="maskfeature1"
      masktype="alpha"
      maskUnits="userSpaceOnUse"
      x="201"
      y="45"
      width="25"
      height="35"
    >
      <path
        d="M204.663 79.8992C204.663 79.8992 197.999 65.5629 204.11 57.0952C210.221 48.6275 222.315 45.1375 222.315 45.1375C222.315 45.1375 229.456 62.0309 221.736 70.4274C214.016 78.822 206.619 77.8939 204.663 79.8992Z"
        fill="#B8F9D8"
      />
    </mask>
    <g mask="url(#maskfeature1)">
      <path
        d="M217.766 45.3622C218.282 46.6597 218.926 47.8286 219.711 48.5636"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M215.215 46.5942C215.912 48.5742 216.867 50.5512 218.106 51.5875"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M212.662 48.0871C213.392 50.4609 214.539 53.1535 216.2 54.4052"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M210.345 49.9715C211.13 52.5412 212.489 55.8568 214.542 57.2586"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M208.025 51.8629C208.831 54.534 210.431 58.6704 212.945 60.235"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M205.706 54.1749C206.546 56.8294 208.407 61.6072 211.31 63.2586"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M203.619 56.6461C204.522 59.2431 206.739 64.6546 210.013 66.3645"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M201.763 60.2625C202.898 63.0808 205.218 67.8489 208.406 69.3824"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M200.836 65.437C202.297 68.2164 204.482 71.4831 207.106 72.6481"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M201.068 71.4957C202.486 73.3684 204.163 75.0501 205.967 75.792"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M224.105 47.29C222.774 47.5464 221.389 47.9139 220.084 48.4364"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M224.814 50.0584C222.669 50.0984 220.188 50.422 217.997 51.4144"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M225.505 53.4227C222.873 52.9937 219.206 52.8426 216.143 54.3781"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M226.096 57.285C223.498 56.3276 218.523 55.035 214.519 57.2187"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M226.764 61.7985C226.764 61.7985 218.817 56.7233 212.896 60.1929"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M225.572 65.5679C225.572 65.5679 217.421 59.435 211.271 63.2711"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M224.538 69.335C224.538 69.335 216.257 62.1892 209.881 66.3909"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M221.727 71.6908C218.992 69.5373 213.3 66.023 208.488 69.3492"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M219.518 74.3949C216.488 72.0825 211.48 69.4309 207.098 72.5924"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
      <path
        d="M216.618 76.4086C213.626 74.3633 209.622 72.8864 205.938 75.6501"
        stroke="#5B9F7D"
        strokeWidth="0.180725"
        strokeMiterlimit="2"
      />
    </g>
    <path
      d="M206.127 130.975H189.464L186.688 113.791H208.905L206.127 130.975Z"
      fill="#5B9F7D"
    />
    <path
      d="M198.075 130.975H191.502L188.312 113.791H196.541L198.075 130.975Z"
      fill="#7FE0AF"
    />
    <path
      d="M188.08 117.436H204.744"
      stroke="#69AEEB"
      strokeWidth="0.00158394"
      strokeMiterlimit="2"
    />
    <path
      d="M72.1442 77.5921L72.1452 77.6001L72.1467 77.608L73.5374 84.7616L73.5382 84.7652L83.2177 130.592H38.1691L38.1245 130.414C38.0671 130.185 37.9826 129.847 37.8742 129.41C37.6573 128.536 37.3445 127.268 36.9611 125.69C36.1943 122.535 35.145 118.143 34.0151 113.196C31.7547 103.298 29.1734 91.185 27.8839 82.3015L27.6604 82.334L27.8839 82.3015C26.9962 76.187 28.2312 72.0653 30.272 69.3852C32.3156 66.7015 35.1889 65.4343 37.629 65.0723L37.6291 65.0723C41.7365 64.461 46.2481 64.5225 52.4279 64.6066C54.8784 64.64 57.5912 64.677 60.6451 64.677C65.5909 64.677 68.4603 67.869 70.1055 71.1102C70.9277 72.7299 71.4379 74.352 71.7426 75.5707C71.8948 76.1796 71.9954 76.6866 72.0579 77.0407C72.0891 77.2177 72.1108 77.3565 72.1246 77.4505C72.1316 77.4975 72.1365 77.5334 72.1397 77.5572L72.1432 77.584L72.144 77.5904L72.1442 77.5919L72.1442 77.5921Z"
      fill="#7FE0AF"
      stroke="black"
      strokeWidth="0.451813"
    />
    <path
      d="M73.3859 77.5649C73.3859 77.5649 71.9652 64.4511 62.908 64.4511C53.3155 64.4511 47.4536 63.9919 42.3068 64.8488C37.8615 65.5868 31.828 70.0146 33.4257 82.334C35.7335 100.12 42.6614 130.818 42.6614 130.818H83.3322L74.6292 84.7185L73.3859 77.5649Z"
      fill="#5B9F7D"
    />
    <path
      d="M119.656 54.0715L118.481 47.2192L113.636 45.2607C113.636 45.2607 113.586 46.8273 114.762 47.806L115.936 48.7848L117.11 52.8968L119.656 54.0715Z"
      fill="#F5E0D3"
    />
    <path
      d="M66.392 96.1623L49.1653 94.7927L59.5414 125.725L66.3934 96.1626L66.3932 96.1627L66.3931 96.1625L66.392 96.1623Z"
      fill="#7FE0AF"
      stroke="black"
      strokeWidth="0.000370947"
    />
    <path
      d="M120.779 59.0605L114.001 66.4197L104.128 48.6518L112.799 43.8331L120.779 59.0605Z"
      fill="#F3F0EE"
    />
    <path
      d="M110.158 94.5935C110.158 94.5935 120.241 123.666 133.945 128.953C147.65 134.238 126.114 85.9796 121.807 83.6302C117.5 81.2808 110.158 94.5935 110.158 94.5935Z"
      fill="#E7C0A6"
    />
    <path
      d="M66.4479 95.0382C66.4479 95.0382 64.1469 111.826 61.0151 119.852C57.8824 127.879 57.0143 131.64 52.9392 128.075C49.4151 124.992 47.3105 116.72 47.3105 113.784C47.3105 110.847 50.2962 94.7448 50.2962 94.7448L66.4479 95.0382Z"
      fill="#F6DDCC"
    />
    <path
      d="M59.1208 112.998C59.1208 112.998 100.429 123.57 104.541 122.983C108.652 122.395 117.854 118.871 117.854 118.871C117.854 118.871 124.901 124.939 126.076 126.115C127.251 127.289 123.727 129.638 120.986 130.225C118.246 130.813 59.5124 130.225 59.5124 130.225C59.5124 130.225 48.1573 130.225 52.856 121.024C57.5546 111.824 59.1208 112.998 59.1208 112.998Z"
      fill="#F6DDCC"
    />
    <path
      d="M89.9824 40.1743C89.9824 40.1743 90.1774 33.126 90.7652 30.1897C91.3531 27.2534 94.0934 25.8828 98.2054 24.5121C102.316 23.1414 101.729 23.5333 100.75 22.7505C99.771 21.9667 103.295 21.1839 107.602 22.9455C111.909 24.7081 118.76 29.211 113.868 36.4542C108.972 43.6984 90.5693 47.8094 89.9824 40.1743Z"
      fill="#212957"
    />
    <path
      d="M81.4014 56.7123C81.4014 56.7123 84.632 56.6158 86.7854 54.0704C88.9389 51.5251 90.5055 48.3918 89.9177 46.2384C89.3298 44.0849 88.1561 36.8417 90.8965 38.4083C93.6378 39.9739 92.854 39.7779 93.4418 41.1486C94.0287 42.5193 94.2246 41.5405 94.8125 39.9739C95.3994 38.4082 97.3569 35.6669 98.9235 35.275C100.489 34.8841 100.489 29.5984 99.5104 28.4237C98.5316 27.249 103.817 29.5984 107.537 30.1853C111.257 30.7731 112.824 30.5772 114.39 33.9054C115.956 37.2326 115.76 40.7577 114.782 44.2818C113.802 47.805 109.496 55.4411 108.908 57.2017C108.32 58.9643 108.712 59.3552 105.58 59.3552C102.448 59.3552 103.817 59.5521 104.013 61.9015C104.209 64.2509 104.992 72.8648 97.3569 73.2558C89.7217 73.6477 82.9679 70.8079 81.4014 56.7123Z"
      fill="#F7DDCC"
    />
    <path
      d="M108.303 56.4171C108.303 58.3747 127.04 119.168 133.363 128.365C136.593 133.064 141.487 123.274 141.879 116.619C142.27 109.963 120.637 68.3602 120.637 68.3602L123.182 59.7453C123.182 59.7453 124.749 59.5503 121.225 54.8515C117.7 50.1526 116.722 50.1526 116.722 50.1526L113.784 51.5233L112.023 54.6555C112.023 54.6555 114.216 59.7346 115.547 59.3534C116.918 58.9625 114.96 57.5918 116.526 57.1999C118.092 56.809 119.267 57.7878 119.267 57.7878L115.547 64.4441C115.547 64.4441 113.784 66.0108 112.415 63.4654C111.044 60.92 108.303 54.8515 108.303 56.4171Z"
      fill="#F6DDCC"
    />
    <path
      d="M115.557 59.3597C116.928 58.9687 114.97 57.5981 116.536 57.2062C118.102 56.8153 119.277 57.794 119.277 57.794L115.557 64.4504C115.557 64.4504 113.794 66.017 112.425 63.4716C111.054 60.9263 108.998 55.4456 108.998 57.0112C108.998 57.8438 111.708 68.732 116.138 82.3304C116.179 78.1892 116.006 72.9551 115.361 70.9108C114.186 67.1907 115.949 67.9745 117.319 66.7998C118.689 65.6251 121.821 58.1859 121.821 58.1859L118.885 55.6406C118.885 55.6406 117.71 55.6406 116.144 55.2487C114.578 54.8577 113.794 51.5295 113.794 51.5295L112.033 54.6618C112.033 54.6618 114.226 59.7408 115.557 59.3597Z"
      fill="#E7C0A6"
    />
    <path
      d="M146.608 89.4468L146.608 89.4468C146.776 88.9845 147.12 88.5564 147.547 88.2435C147.974 87.9301 148.467 87.7452 148.92 87.7452H190.32C190.767 87.7452 191.076 87.9236 191.24 88.1825C191.407 88.4448 191.452 88.831 191.284 89.2924L177.364 127.538H132.744L146.608 89.4468Z"
      fill="#7FE0AF"
      stroke="black"
      strokeWidth="0.451813"
    />
    <path
      d="M96.4746 127.531V128.668C96.4746 129.672 97.2896 130.487 98.2937 130.487H176.055C177.059 130.487 177.873 129.672 177.873 128.668V127.531H96.4746Z"
      fill="#5B9F7D"
    />
    <path
      d="M132.399 128.668V127.531H96.4746V128.668C96.4746 129.672 97.2896 130.487 98.2937 130.487H130.58C131.585 130.487 132.399 129.672 132.399 128.668Z"
      fill="#77E0AB"
    />
    <path
      d="M92.042 48.9785C92.042 48.9785 94.0005 54.0693 95.5652 55.6349C97.1318 57.2005 103.397 58.7671 105.551 59.354C107.704 59.9418 104.963 58.7671 99.8731 60.3337C94.7833 61.8994 88.127 65.2276 88.127 62.8781C88.127 60.5297 88.2244 56.2227 89.0073 54.6561C89.7911 53.0895 92.042 48.9785 92.042 48.9785Z"
      fill="#EED0BB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.807 33.8922C159.807 41.966 153.26 48.5122 145.186 48.5122C137.112 48.5122 130.565 41.966 130.565 33.8922C130.565 25.8174 137.112 19.2722 145.186 19.2722C153.26 19.2722 159.807 25.8174 159.807 33.8922Z"
      stroke="#9AC7F7"
      strokeWidth="0.0031716"
      strokeMiterlimit="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.508 33.8922C153.508 41.966 149.722 48.5122 145.054 48.5122C140.383 48.5122 136.596 41.966 136.596 33.8922C136.596 25.8174 140.383 19.2722 145.054 19.2722C149.722 19.2722 153.508 25.8174 153.508 33.8922Z"
      stroke="#9AC7F7"
      strokeWidth="0.0031716"
      strokeMiterlimit="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.176 48.5122C153.25 48.5122 159.797 41.966 159.797 33.8922C159.797 25.8174 153.25 19.2722 145.176 19.2722V48.5122Z"
      stroke="#9AC7F7"
      strokeWidth="0.0031716"
      strokeMiterlimit="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.565 33.8921C130.565 35.7083 130.913 37.4407 131.517 39.0443H158.855C159.46 37.4407 159.807 35.7083 159.807 33.8921C159.807 32.076 159.46 30.3427 158.855 28.7371H131.518C130.913 30.3427 130.565 32.076 130.565 33.8921Z"
      stroke="#9AC7F7"
      strokeWidth="0.0031716"
      strokeMiterlimit="2"
    />
  </svg>
)

export default Feature1
