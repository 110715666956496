import React from "react"
import styled from "@emotion/styled"
import Feature1 from "../ui/images/features/feature-1"
import Feature2 from "../ui/images/features/feature-2"
import Feature3 from "../ui/images/features/feature-3"
import Feature4 from "../ui/images/features/feature-4"
import Feature5 from "../ui/images/features/feature-5"
import Isotipo from "../ui/images/isotipo"

type TypeFeature = {
  image: JSX.Element
  title: string
  description: string
}

const listFeature: TypeFeature[] = [
  {
    image: <Feature1 />,
    title:
      "En Capitaria te apoyamos desde el primer momento sin importar tu nivel de conocimiento",
    description:
      "Sabemos que en un principio el mercado financiero puede asustar un poco, por eso te ayudamos desde un inicio a través de asesorías personalizadas, charlas de mercado, webinars y reportes económicos todos los días.",
  },
  {
    image: <Feature2 />,
    title: "Sin comisiones ni cobros por abrir una cuenta",
    description:
      "Crearte una cuenta no tiene costo. El Capital que deposites es el que aparecerá en la plataforma para que puedas operar. No cobramos comisiones por depósitos ni retiros.",
  },
  {
    image: <Feature3 />,
    title: "Somos un broker multinacional con oficinas en 3 países",
    description:
      "Nuestras oficinas en Chile, Perú y Uruguay siempre están abiertas para recibir a nuestros traders ya sea para dudas, cursos formativos y eventos exclusivos. Si eres de otro país, nuestro equipo de Uruguay siempre está atento a lo que necesites a pesar de la distancia",
  },
  {
    image: <Feature4 />,
    title: "Retiros en menos de 48 horas sin importar el monto",
    description:
      "En Capitaria estamos 100% comprometido con nuestros clientes y queremos que disfruten sus ganancias. Es por eso que el 98% de los retiros ocurren en menos de 4 horas. Olvídate de recibir tu dinero días después de solicitarlo.",
  },
  {
    image: <Feature5 />,
    title: "Mesa de dinero y equipo de ejecutivos a tu disposición",
    description:
      "Llámanos cuando quieras, para lo que necesites. Siempre habrá un trader dispuesto a ayudarte. #SomosTraders para Traders",
  },
]

type Props = TypeFeature & {
  index: number
}

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    min-width: 640px;
    margin-bottom: 100px;
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
    }
  }
  @media (min-width: 1024px) {
    min-width: 846px;
  }
`

const FeatureImage = styled.div`
  text-align: center;
  margin-bottom: 45px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const FeatureDetails = styled.div`
  max-width: 320px;
  @media (min-width: 1024px) {
    max-width: 420px;
  }
`
const FeatureTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 115.5%;
  margin-bottom: 15px;
`
const FeatureIndex = styled.div`
  font-size: 33.18px;
  margin-bottom: 14px;
`
const FeatureDescription = styled.div`
  font-size: 16px;
  line-height: 140%;
`

const Feature = ({ image, title, description, index }: Props) => {
  return (
    <FeatureContainer>
      <FeatureImage>{image}</FeatureImage>
      <FeatureDetails>
        <FeatureTitle>
          <FeatureIndex>
            {index + 1}
            <Isotipo width="12" height="23" />
          </FeatureIndex>
          {title}
        </FeatureTitle>
        <FeatureDescription>{description}</FeatureDescription>
      </FeatureDetails>
    </FeatureContainer>
  )
}

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eeeeee;
  padding-top: 50px;
  width: 100%;
`

const Features = () => (
  <FeaturesContainer>
    {listFeature.map((data: TypeFeature, idx: number) => (
      <Feature key={`key-feature-${idx}`} index={idx} {...data} />
    ))}
  </FeaturesContainer>
)

export default Features
