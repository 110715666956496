import React from "react"

const Step3 = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="35.5" stroke="url(#step_3_circle)" />
    <path
      d="M42.2375 31.6764C42.2375 32.9095 41.8639 33.9581 41.1169 34.8221C40.3698 35.6862 39.3212 36.2802 37.9711 36.6042V36.6853C39.5643 36.8833 40.7703 37.3693 41.5894 38.1434C42.4085 38.9084 42.818 39.9435 42.818 41.2486C42.818 43.1477 42.1294 44.6283 40.7523 45.6904C39.3752 46.7435 37.4086 47.27 34.8524 47.27C32.7103 47.27 30.8111 46.9145 29.155 46.2034V42.6527C29.9201 43.0397 30.7616 43.3547 31.6797 43.5978C32.5978 43.8408 33.5068 43.9623 34.4069 43.9623C35.784 43.9623 36.8011 43.7283 37.4581 43.2602C38.1152 42.7922 38.4437 42.0406 38.4437 41.0056C38.4437 40.0785 38.0656 39.4215 37.3096 39.0344C36.5535 38.6474 35.3475 38.4539 33.6913 38.4539H32.1927V35.2542H33.7183C35.2484 35.2542 36.3645 35.0561 37.0666 34.6601C37.7776 34.2551 38.1332 33.5665 38.1332 32.5945C38.1332 31.1004 37.1971 30.3533 35.325 30.3533C34.6769 30.3533 34.0154 30.4613 33.3403 30.6773C32.6743 30.8933 31.9317 31.2669 31.1126 31.7979L29.182 28.9222C30.9821 27.6261 33.1288 26.9781 35.622 26.9781C37.6651 26.9781 39.2762 27.3921 40.4553 28.2201C41.6434 29.0482 42.2375 30.2003 42.2375 31.6764Z"
      fill="url(#step_3_number)"
    />
    <defs>
      <linearGradient
        id="step_3_circle"
        x1="36"
        y1="0"
        x2="36"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="step_3_number"
        x1="36"
        y1="20"
        x2="36"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </svg>
)

export default Step3
