import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"
import { Link } from "@reach/router"

import Step1Image from "../ui/images/steps/step-1"
import Step2Image from "../ui/images/steps/step-2"
import Step3Image from "../ui/images/steps/step-3"
import { trackEvent } from "../../helpers/tracker"
import Button from "../ui/button"

const LinkAccountType = styled(Link)``

const Steps2 = () => {
  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  const onClick = () =>
    trackEvent("porque-capitaria:account-type", {
      mc_origen: mcOrigen,
    })
  return (
    <div>
      Elige entre cuenta Start, Pro o Premium
      <br />
      <LinkAccountType
        key="lin-account-type"
        to={`/tipos-de-cuentas${queryString}`}
        onClick={onClick}
      >
        Ver tipos de cuentas
      </LinkAccountType>
    </div>
  )
}

const WrapperSteps = styled.div`
  background: #e5e5e5;
  color: #000000;
  font-family: "Open Sans";
  width: 100%;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StepsInfo = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
`

const StepsTitle = styled.h2`
  font-size: 23.04px;
  line-height: 115.5%;
  font-weight: bold;
  padding: 0 20px;
  span {
    color: #74b44c;
  }
`

const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
  @media (min-width: 768px) {
    justify-content: space-evenly;
    flex-direction: row;
  }
`

const Step = styled.div`
  max-width: 280px;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    max-width: 170px;
    padding: 0;
  }
  @media (min-width: 1024px) {
    max-width: 286px;
  }
`

const Image = styled.div`
  height: 72px;
`

const Title = styled.div`
  font-size: 16px;
  line-height: 140%;
  font-weight: bold;
  margin-top: 10px;
`

const Subject = styled.div`
  font-size: 11.11px;
  line-height: 16px;
  margin-top: 10px;
  a {
    color: #000000;
  }
  @media (min-width: 1024px) {
    max-width: 237px;
  }
`

const Br = styled.br`
  display: none;
  @media (min-width: 1024px) {
    display: initial;
  }
`

const Steps = () => {
  const dataSteps = [
    {
      image: <Step1Image />,
      title: "Crea tu cuenta",
      subject:
        "Simplemente rellena tu información personal y un breve cuestionario para conocer tu perfil de Trader",
    },
    {
      image: <Step2Image />,
      title: "Selecciona un tipo de Cuenta",
      subject: <Steps2 />,
    },
    {
      image: <Step3Image />,
      title: "Deposita y descarga la Plataforma",
      subject:
        "Deposita tu capital de inversión, descarga la plataforma y ya estás listo para vivir los mercados desde adentro",
    },
  ]

  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  const onClickCreateAccount = () =>
    trackEvent("porque-capitaria:steps:create-account", {
      mc_origen: mcOrigen,
    })

  return (
    <WrapperSteps>
      <StepsInfo>
        <StepsTitle>
          ¿Estás listo para tomar el control de tu Capital de Riesgo? <Br />
          <span>Esto es lo que tienes que hacer</span>
        </StepsTitle>
      </StepsInfo>
      <StepsContainer>
        {dataSteps.map((step: any, idx: number) => (
          <Step key={`step-${idx}`}>
            <Image>{step.image}</Image>
            <Title>{step.title}</Title>
            <Subject>{step.subject}</Subject>
          </Step>
        ))}
      </StepsContainer>
      <Button
        href={`https://onboarding.capitaria.com/sign-up${queryString}`}
        onClick={onClickCreateAccount}
      >
        CREA TU CUENTA
      </Button>
    </WrapperSteps>
  )
}

export default Steps
