import React from "react"

const Feature5 = () => (
  <svg
    width="245"
    height="144"
    viewBox="0 0 245 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M228.776 143.043H2.22318C-10.5462 73.1301 34.7645 60.1117 59.8913 55.2901C104.79 46.6112 118.795 29.7356 134.448 18.1637C170.696 -11.7303 202.002 2.73457 216.007 9.48483C269.144 36.0037 233.307 109.774 231.66 118.453C230.342 125.396 229.188 137.74 228.776 143.043Z"
      fill="#F8F8F8"
    />
    <path
      d="M178.247 61.2339H172.054V83.3823H178.247V61.2339Z"
      fill="#322F4D"
    />
    <path
      d="M180.634 81.6686H169.379V83.0637H180.634V81.6686Z"
      fill="#1B1523"
    />
    <path
      d="M187.883 83.0636H162.023V85.1069H187.883V83.0636Z"
      fill="#1B1523"
    />
    <path
      d="M178.247 68.3148H172.054V72.4912H178.247V68.3148Z"
      fill="#1B1523"
    />
    <path
      d="M182.728 29.7129H159.794V67.4562H182.728V29.7129Z"
      fill="#C2F8DC"
    />
    <path
      d="M160.601 30.562H181.836V66.606H160.601V30.562ZM158.902 68.3049H183.535V28.863H158.902V68.3049Z"
      fill="#322F4D"
    />
    <path d="M181.92 30.5627H160.686V43.9254H181.92V30.5627Z" fill="#B7E8CD" />
    <path
      d="M171.303 49.1184H160.686V57.8624H171.303V49.1184Z"
      fill="#B7E8CD"
    />
    <path d="M179.254 46.5741H174.06V47.3317H179.254V46.5741Z" fill="#DCF2FD" />
    <path d="M179.254 49.1654H174.06V49.923H179.254V49.1654Z" fill="#DCF2FD" />
    <path d="M179.254 51.7585H174.06V52.5161H179.254V51.7585Z" fill="#DCF2FD" />
    <path
      d="M132.444 46.1473H127.249V46.9045H132.444V46.1473Z"
      fill="#DCF2FD"
    />
    <path
      d="M132.444 48.7386H127.249V49.4958H132.444V48.7386Z"
      fill="#DCF2FD"
    />
    <path d="M132.444 51.3318H127.249V52.089H132.444V51.3318Z" fill="#DCF2FD" />
    <path
      d="M87.2452 63.1823V61.0076H86.2334V63.1823C86.2334 69.8491 91.657 75.2731 98.3237 75.2731H135.381V74.2609H98.3237C92.2154 74.2609 87.2452 69.2911 87.2452 63.1823Z"
      fill="#322F4D"
    />
    <path d="M106.47 61.2339H100.276V83.3823H106.47V61.2339Z" fill="#322F4D" />
    <path
      d="M108.857 81.6686H97.6025V83.0637H108.857V81.6686Z"
      fill="#1B1523"
    />
    <path
      d="M116.328 83.0636H90.4688V85.1069H116.328V83.0636Z"
      fill="#1B1523"
    />
    <path d="M106.47 68.3148H100.276V72.4912H106.47V68.3148Z" fill="#1B1523" />
    <path
      d="M137.588 27.4803H69.0693V67.4559H137.588V27.4803Z"
      fill="#C2F8DC"
    />
    <path
      d="M69.8767 28.3295H136.697V66.6061H69.8767V28.3295ZM68.1777 68.305H138.395V26.6305H68.1777V68.305Z"
      fill="#322F4D"
    />
    <path
      d="M80.2953 37.9136H69.9609V66.6064H80.2953V37.9136Z"
      fill="#B7E8CD"
    />
    <path
      d="M136.707 28.3302H80.2148V30.8419H136.707V28.3302Z"
      fill="#B7E8CD"
    />
    <path
      d="M136.707 53.8378H80.2148V56.3498H136.707V53.8378Z"
      fill="#B7E8CD"
    />
    <path
      d="M80.2953 53.8378H69.9609V66.6058H80.2953V53.8378Z"
      fill="#B7E8CD"
    />
    <path
      d="M113.032 43.5283H131.517C132.652 43.5283 133.572 42.6077 133.572 41.4727V36.1936C133.572 35.0582 132.652 34.1381 131.517 34.1381H113.032C111.897 34.1381 110.977 35.0582 110.977 36.1936V41.4727C110.977 42.6077 111.897 43.5283 113.032 43.5283Z"
      fill="#B7E8CD"
    />
    <path
      d="M44.446 74.2611V65.3125H43.4346V74.2611C43.4346 78.1679 46.6129 81.3463 50.5197 81.3463H58.0267V89.1063H59.039V80.334H50.5197C47.171 80.334 44.446 77.6099 44.446 74.2611Z"
      fill="#322F4D"
    />
    <path
      d="M198.328 75.9359C198.328 78.1913 196.494 80.0265 194.238 80.0265H169.156V81.0383H194.238C197.052 81.0383 199.341 78.7494 199.341 75.9359V67.1793H198.328V75.9359Z"
      fill="#322F4D"
    />
    <path
      d="M202.268 65.7601H201.256V89.8704H202.268V65.7601Z"
      fill="#322F4D"
    />
    <path
      d="M167.716 91.0465H166.704V123.781H167.716V91.0465Z"
      fill="#1B1523"
    />
    <path
      d="M165.265 91.0465H164.253V123.781H165.265V91.0465Z"
      fill="#1B1523"
    />
    <path d="M242.649 85.108H14.4561V89.9086H242.649V85.108Z" fill="#E2E2E2" />
    <path d="M209.054 85.108H14.4561V89.9086H209.054V85.108Z" fill="white" />
    <path d="M39.8203 61.2339H33.627V83.3823H39.8203V61.2339Z" fill="#322F4D" />
    <path
      d="M42.4292 81.6686H31.1748V83.0637H42.4292V81.6686Z"
      fill="#1B1523"
    />
    <path
      d="M49.6783 83.0636H23.8184V85.1069H49.6783V83.0636Z"
      fill="#1B1523"
    />
    <path d="M39.8203 68.3148H33.627V72.4912H39.8203V68.3148Z" fill="#1B1523" />
    <path d="M212.13 61.2339H205.937V83.3823H212.13V61.2339Z" fill="#322F4D" />
    <path
      d="M214.739 81.6686H203.484V83.0637H214.739V81.6686Z"
      fill="#1B1523"
    />
    <path
      d="M221.988 83.0636H196.129V85.1069H221.988V83.0636Z"
      fill="#1B1523"
    />
    <path d="M212.13 68.3148H205.937V72.4912H212.13V68.3148Z" fill="#1B1523" />
    <path
      d="M220.092 42.2255H184.983V67.4554H220.092V42.2255Z"
      fill="#C2F8DC"
    />
    <path
      d="M185.791 43.0746H219.201V66.6055H185.791V43.0746ZM184.092 68.3045H220.9V41.3756H184.092V68.3045Z"
      fill="#322F4D"
    />
    <path
      d="M60.4874 42.2255H25.3789V67.4554H60.4874V42.2255Z"
      fill="#C2F8DC"
    />
    <path
      d="M26.4089 43.0746H59.8188V66.6055H26.4089V43.0746ZM24.71 68.3045H61.5178V41.3756H24.71V68.3045Z"
      fill="#322F4D"
    />
    <path d="M59.626 43.0753H42.3203V62.5149H59.626V43.0753Z" fill="#B7E8CE" />
    <path
      d="M167.498 83.0566H138.947C138.396 83.0566 137.949 83.5033 137.949 84.0547V84.1082C137.949 84.6596 138.396 85.1066 138.947 85.1066H167.498C168.05 85.1066 168.496 84.6596 168.496 84.1082V84.0547C168.496 83.5033 168.05 83.0566 167.498 83.0566Z"
      fill="#322F4D"
    />
    <path
      d="M173.83 62.3572H146.027C145.506 62.3572 145.082 62.7799 145.082 63.3005V82.1147C145.082 82.6138 145.47 83.0227 145.962 83.0561L146.027 83.0581H173.83C174.118 83.0581 174.376 82.9282 174.549 82.7247C174.689 82.5598 174.773 82.3473 174.773 82.1147V63.3005C174.773 62.7799 174.351 62.3572 173.83 62.3572Z"
      fill="#322F4D"
    />
    <path
      d="M173.317 63.8896H146.419V81.5515H173.317V63.8896Z"
      fill="#C2F8DC"
    />
    <path
      d="M156.243 83.0583H150.432V83.4972H156.243V83.0583Z"
      fill="#494469"
    />
    <path
      d="M190.039 43.0753H185.875V52.2601H190.039V43.0753Z"
      fill="#90D8F3"
    />
    <path
      d="M36.7477 62.1656H26.2705V66.6053H36.7477V62.1656Z"
      fill="#B7E8CE"
    />
    <path
      d="M173.258 75.5142H153.107V81.5509H173.258V75.5142Z"
      fill="#B7E8CD"
    />
    <path
      d="M153.166 63.8896H146.419V75.5148H153.166V63.8896Z"
      fill="#B7E8CD"
    />
    <path d="M217.72 54.806H209.727V63.831H217.72V54.806Z" fill="#B7E8CD" />
    <path
      d="M39.9462 82.1226H38.3893C38.0815 82.1226 37.8332 81.8715 37.8332 81.5669V79.6964C37.8332 79.3917 38.0815 79.1407 38.3893 79.1407H39.9462V82.1226ZM39.9462 77.1564V78.2768H38.3893C37.6061 78.2768 36.9697 78.9136 36.9697 79.6964V81.5669C36.9697 82.3497 37.6061 82.9865 38.3893 82.9865H39.9489C39.9607 84.3249 41.0517 85.1081 42.3933 85.1081H44.2312C45.5815 85.1081 46.6751 84.31 46.6751 82.9593V77.1564H39.9462Z"
      fill="#76D0E9"
    />
    <path
      d="M39.7235 82.1226H38.1666C37.8589 82.1226 37.6109 81.8715 37.6109 81.5669V79.6964C37.6109 79.3917 37.8589 79.1407 38.1666 79.1407H39.7235V82.1226ZM39.7235 77.1564V78.2768H38.1666C37.3838 78.2768 36.7471 78.9136 36.7471 79.6964V81.5669C36.7471 82.3497 37.3838 82.9865 38.1666 82.9865H39.7263C39.7385 84.3249 40.829 85.1081 42.1706 85.1081H44.0081C45.3588 85.1081 46.4525 84.31 46.4525 82.9593V77.1564H39.7235Z"
      fill="#1B1523"
    />
    <path d="M224.358 89.871H71.2988V94.0233H224.358V89.871Z" fill="#B9B9B9" />
    <path d="M224.294 89.871H218.196V142.299H224.294V89.871Z" fill="#D9D9D9" />
    <path d="M186.24 89.871H33.1807V94.0233H186.24V89.871Z" fill="#B9B9B9" />
    <path d="M39.2776 89.871H33.1807V142.299H39.2776V89.871Z" fill="#D9D9D9" />
    <path d="M186.176 89.871H180.079V142.299H186.176V89.871Z" fill="#322F4D" />
    <path d="M39.411 89.871H33.1807V94.6884H39.411V89.871Z" fill="#9F9F9F" />
    <path d="M186.308 89.871H180.078V94.6884H186.308V89.871Z" fill="#1B1523" />
    <path d="M224.427 89.871H218.196V94.6884H224.427V89.871Z" fill="#B9B9B9" />
    <path
      d="M56.4312 46.0255H51.2363V46.7827H56.4312V46.0255Z"
      fill="#DCF2FD"
    />
    <path
      d="M56.4312 48.6185H51.2363V49.3757H56.4312V48.6185Z"
      fill="#DCF2FD"
    />
    <path
      d="M56.4312 51.2098H51.2363V51.9674H56.4312V51.2098Z"
      fill="#DCF2FD"
    />
    <path
      d="M193.076 59.0483H187.881V59.8059H193.076V59.0483Z"
      fill="#DCF2FD"
    />
    <path
      d="M193.076 61.6396H187.881V62.3972H193.076V61.6396Z"
      fill="#DCF2FD"
    />
    <path d="M193.076 64.2328H187.881V64.99H193.076V64.2328Z" fill="#DCF2FD" />
    <path
      d="M171.007 65.3439H165.812V66.1011H171.007V65.3439Z"
      fill="#DCF2FD"
    />
    <path
      d="M171.007 67.9352H165.812V68.6928H171.007V67.9352Z"
      fill="#DCF2FD"
    />
    <path
      d="M171.007 70.5265H165.812V71.2841H171.007V70.5265Z"
      fill="#DCF2FD"
    />
    <path
      d="M151.521 72.9272H157.311V67.1369H151.521V72.9272ZM158.178 73.7938H150.654V66.2703H158.178V73.7938Z"
      fill="white"
    />
    <path
      d="M156.202 77.483H161.993V71.6927H156.202V77.483ZM162.859 78.3496H155.336V70.826H162.859V78.3496Z"
      fill="white"
    />
    <path d="M242.598 85.108H14.4561V85.7066H242.598V85.108Z" fill="#C8F0B9" />
    <path
      d="M130.171 36.8543H125.63C125.293 36.8543 125.02 36.5808 125.02 36.2435C125.02 35.9058 125.293 35.6323 125.63 35.6323H130.171C130.508 35.6323 130.782 35.9058 130.782 36.2435C130.782 36.5808 130.508 36.8543 130.171 36.8543Z"
      fill="white"
    />
    <path
      d="M130.171 39.2767H125.63C125.293 39.2767 125.02 39.0031 125.02 38.6659C125.02 38.3282 125.293 38.0547 125.63 38.0547H130.171C130.508 38.0547 130.782 38.3282 130.782 38.6659C130.782 39.0031 130.508 39.2767 130.171 39.2767Z"
      fill="white"
    />
    <path
      d="M130.171 41.7007H125.63C125.293 41.7007 125.02 41.4272 125.02 41.09C125.02 40.7523 125.293 40.4788 125.63 40.4788H130.171C130.508 40.4788 130.782 40.7523 130.782 41.09C130.782 41.4272 130.508 41.7007 130.171 41.7007Z"
      fill="white"
    />
    <path
      d="M167.004 33.4226H162.689C162.444 33.4226 162.246 33.2242 162.246 32.9794C162.246 32.7346 162.444 32.5359 162.689 32.5359H167.004C167.249 32.5359 167.447 32.7346 167.447 32.9794C167.447 33.2242 167.249 33.4226 167.004 33.4226Z"
      fill="white"
    />
    <path
      d="M167.004 35.4423H162.689C162.444 35.4423 162.246 35.244 162.246 34.9992C162.246 34.7544 162.444 34.556 162.689 34.556H167.004C167.249 34.556 167.447 34.7544 167.447 34.9992C167.447 35.244 167.249 35.4423 167.004 35.4423Z"
      fill="white"
    />
    <path
      d="M167.004 37.4642H162.689C162.444 37.4642 162.246 37.2658 162.246 37.021C162.246 36.7762 162.444 36.5779 162.689 36.5779H167.004C167.249 36.5779 167.447 36.7762 167.447 37.021C167.447 37.2658 167.249 37.4642 167.004 37.4642Z"
      fill="white"
    />
    <path
      d="M130.643 52.4249H114.299C112.71 52.4249 111.422 51.1368 111.422 49.548V49.4036C111.422 47.8148 112.71 46.5271 114.299 46.5271H130.643C132.232 46.5271 133.52 47.8148 133.52 49.4036V49.548C133.52 51.1368 132.232 52.4249 130.643 52.4249Z"
      fill="#B7E8CD"
    />
    <path d="M106.821 32.4906H80.2148V53.8377H106.821V32.4906Z" fill="white" />
    <path d="M39.9943 45.6458H28.9453V57.9404H39.9943V45.6458Z" fill="white" />
    <path d="M217.72 44.9249H209.727V52.711H217.72V44.9249Z" fill="white" />
    <path
      d="M119.685 80.8745H117.887V85.1072H122.268V83.457C122.268 82.0304 121.112 80.8745 119.685 80.8745Z"
      fill="#DB7372"
    />
    <path
      d="M117.073 80.5035H110.977V85.7594H117.073C118.525 85.7594 119.701 84.5831 119.701 83.1313C119.701 81.6799 118.525 80.5035 117.073 80.5035Z"
      fill="#4D8267"
    />
    <path
      d="M107.972 135.617H102.061V142.3H114.431V142.075C114.431 138.508 111.539 135.617 107.972 135.617Z"
      fill="#8EC4A0"
    />
    <path
      d="M111.872 107.611V105.689C111.872 100.27 107.479 95.8774 102.061 95.8774V135.616H111.872V112.963C111.817 112.918 111.872 107.611 111.872 107.611Z"
      fill="#C4C4C4"
    />
    <path
      d="M87.6865 135.617H81.7754V142.3H94.1456V142.075C94.1456 138.508 91.2537 135.617 87.6865 135.617Z"
      fill="#8EC4A0"
    />
    <path
      d="M81.7754 95.8774V135.616H91.5871V105.689C91.5871 100.27 87.1942 95.8774 81.7754 95.8774Z"
      fill="#C4C4C4"
    />
    <path
      d="M104.263 95.8774H97.8252V105.637H111.838V103.451C111.838 99.2683 108.446 95.8774 104.263 95.8774Z"
      fill="#C4C4C4"
    />
    <path d="M86.2218 48.9965H77.54V65.7599H86.2218V48.9965Z" fill="#E4D2C5" />
    <path
      d="M86.9525 42.1973L79.7686 42.3515L80.0492 55.0039C80.1172 58.0862 82.8406 60.5278 86.1315 60.457L87.9368 60.4184C90.5759 60.3617 92.6704 58.3121 92.6157 55.8406L92.4221 47.101C92.3607 44.3292 89.912 42.1335 86.9525 42.1973Z"
      fill="#F6DDCC"
    />
    <path
      d="M75.9795 48.7625L77.6163 58.0232L84.5326 57.8382C87.7495 57.752 90.3166 55.2219 90.2663 52.1868L90.1372 44.3937L75.9795 48.7625Z"
      fill="#212A54"
    />
    <path
      d="M92.1164 39.3137H83.7466C79.4569 39.3137 75.9795 42.7916 75.9795 47.0813V48.6744L92.1164 49.3749V39.3137Z"
      fill="#212A54"
    />
    <path
      d="M89.7375 47.9587C89.1342 47.9772 88.6635 49.1768 88.6863 50.638C88.7092 52.0997 89.2165 53.2693 89.8198 53.2508C90.4231 53.2323 90.8938 52.0328 90.871 50.5715C90.8481 49.1102 90.3408 47.9406 89.7375 47.9587Z"
      fill="#E4D2C5"
    />
    <path
      d="M87.1199 62.0194H77.54V65.7601H89.397V64.2969C89.397 63.0391 88.3777 62.0194 87.1199 62.0194Z"
      fill="#7ABA9A"
    />
    <path
      d="M90.3876 64.3651H77.3161C75.2236 64.3651 73.5273 66.0613 73.5273 68.1539V105.638H97.9953V71.9729C97.9953 67.7713 94.5891 64.3651 90.3876 64.3651Z"
      fill="#7FE0AF"
    />
    <path
      d="M98.0171 86.9462V76.2317L93.5889 74.1384L98.0171 86.9462Z"
      fill="#1B1523"
    />
    <path
      d="M94.0928 102.152C91.1687 104.626 86.0107 105.637 86.0107 105.637H97.839V96.6873C97.839 96.6873 97.0165 99.6779 94.0928 102.152Z"
      fill="#C4C4C4"
    />
    <path
      d="M103.813 105.637H71.3301C69.3426 105.637 67.7314 107.248 67.7314 109.235C67.7314 111.222 69.3426 112.834 71.3301 112.834H103.813C105.801 112.834 107.411 111.222 107.411 109.235C107.411 107.248 105.801 105.637 103.813 105.637Z"
      fill="#8EC5A0"
    />
    <path
      d="M103.76 105.637H89.6094C87.622 105.637 86.0107 107.248 86.0107 109.235C86.0107 111.222 87.622 112.834 89.6094 112.834H103.76C105.747 112.834 107.358 111.222 107.358 109.235C107.358 107.248 105.747 105.637 103.76 105.637Z"
      fill="#A5DBB7"
    />
    <path
      d="M80.4375 114.223C80.4375 114.987 81.0569 115.606 81.8208 115.606H92.4759C93.2398 115.606 93.8588 114.987 93.8588 114.223V112.834H80.4375V114.223Z"
      fill="#787878"
    />
    <path
      d="M88.3449 115.607H86.0107V122.901H88.3449V115.607Z"
      fill="#787878"
    />
    <path d="M89.7583 122.9H84.8955V132.723H89.7583V122.9Z" fill="#787878" />
    <path d="M97.8525 95.6335H96.041V107.691H97.8525V95.6335Z" fill="white" />
    <path
      d="M83.3588 96.3456C83.171 97.3389 83.077 97.836 82.8896 98.8297C90.6604 94.7505 100.169 96.1646 106.426 102.128C107.01 102.685 107.98 102.366 108.13 101.572L108.135 101.547C108.194 101.232 108.107 100.899 107.891 100.662C101.683 93.8509 91.5541 92.0433 83.3588 96.3456Z"
      fill="white"
    />
    <path
      d="M86.7665 106.196C86.3934 107.241 85.3001 107.934 84.0852 107.934H69.4316C67.5878 107.934 66.2387 106.373 66.6941 104.761C68.4033 98.7236 69.1711 87.64 64.3776 75.9223C63.5622 73.9231 65.1931 71.8065 67.5532 71.8065H80.7884C82.1505 71.8065 83.387 72.5381 83.916 73.6649C86.953 80.1479 88.2195 86.5211 88.4844 92.0533C88.7661 98.0821 87.8637 103.113 86.7665 106.196Z"
      fill="#EDFFE7"
    />
    <path
      d="M87.0577 106.196C86.7212 107.241 85.7353 107.933 84.6397 107.933H76.0795C75.8213 107.472 75.6686 107.045 75.8095 106.846C76.2007 106.29 86.1226 92.0531 86.1226 92.0531H88.6071C88.8613 98.0819 88.0475 103.113 87.0577 106.196Z"
      fill="#8BD6A5"
      fillOpacity="0.4049"
    />
    <path
      d="M79.1589 72.3081H67.2213C65.0937 72.3081 63.6207 74.3983 64.357 76.3664C68.6786 87.9215 67.9856 98.8525 66.4468 104.806C66.0363 106.395 67.2524 107.935 68.9151 107.935H82.1314C83.2271 107.935 84.2126 107.249 84.5479 106.221C86.4475 100.392 87.7006 87.4969 81.9772 74.144C81.4994 73.0303 80.3853 72.3081 79.1589 72.3081Z"
      fill="#ADE9C1"
    />
    <path
      d="M77.9854 136.748V139.676H80.3191V136.748C80.3191 135.199 82.4793 133.89 85.0363 133.89H89.6074V131.555H85.0363C81.0822 131.555 77.9854 133.836 77.9854 136.748Z"
      fill="#787878"
    />
    <path
      d="M98.3353 131.555H75.6749C71.7209 131.555 68.624 133.836 68.624 136.748V139.676H70.9582V136.748C70.9582 135.199 73.118 133.89 75.6749 133.89H98.3353C100.892 133.89 103.052 135.199 103.052 136.748V139.676H105.386V136.748C105.386 133.836 102.289 131.555 98.3353 131.555Z"
      fill="#787878"
    />
    <path
      d="M91.6957 131.555H88.2919C87.6472 131.555 87.125 132.078 87.125 132.723C87.125 133.367 87.6472 133.89 88.2919 133.89H91.6957C94.2526 133.89 96.4125 135.199 96.4125 136.748V139.676H98.7466V136.748C98.7466 133.836 95.6493 131.555 91.6957 131.555Z"
      fill="#787878"
    />
    <path
      d="M104.46 137.053C103.011 137.053 101.837 138.228 101.837 139.677C101.837 141.126 103.011 142.3 104.46 142.3C105.909 142.3 107.084 141.126 107.084 139.677C107.084 138.228 105.909 137.053 104.46 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M97.5502 137.053C96.1011 137.053 94.9268 138.228 94.9268 139.677C94.9268 141.126 96.1011 142.3 97.5502 142.3C98.9993 142.3 100.174 141.126 100.174 139.677C100.174 138.228 98.9993 137.053 97.5502 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M79.2709 137.053C77.8218 137.053 76.6475 138.228 76.6475 139.677C76.6475 141.126 77.8218 142.3 79.2709 142.3C80.7199 142.3 81.8943 141.126 81.8943 139.677C81.8943 138.228 80.7199 137.053 79.2709 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M69.6869 137.053C68.2382 137.053 67.0635 138.228 67.0635 139.677C67.0635 141.126 68.2382 142.3 69.6869 142.3C71.136 142.3 72.3107 141.126 72.3107 139.677C72.3107 138.228 71.136 137.053 69.6869 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M90.9141 71.6018L105.072 85.7598L109.97 80.8621L95.8118 66.704L90.9141 71.6018Z"
      fill="#7ABA9A"
    />
    <path
      d="M115.833 80.5035H105.181V85.7594H115.833C117.261 85.7594 118.418 84.602 118.418 83.1746V83.088C118.418 81.6606 117.261 80.5035 115.833 80.5035Z"
      fill="#7ABA9A"
    />
    <path
      d="M111.126 103.451V104.854H111.12L111.125 105.641L111.126 105.689V106.828L111.908 107.611V105.689L111.907 105.637H111.908V103.451C111.908 99.2683 108.517 95.8774 104.334 95.8774H101.392C101.394 95.8786 102.903 96.6327 102.977 96.6866L104.334 96.6594C108.079 96.6594 111.126 99.7063 111.126 103.451Z"
      fill="white"
    />
    <path
      d="M118.07 81.5471L118.255 81.6573H119.856C120.849 81.6573 121.656 82.4649 121.656 83.4574V84.1706H122.439V83.4574C122.439 82.0308 121.282 80.8749 119.856 80.8749H118.47C118.077 80.6408 117.62 80.5042 117.129 80.5042H109.769L95.9686 66.7043L95.9371 66.7357C94.5511 65.2784 92.5982 64.365 90.4282 64.365H89.5753V64.2969C89.5753 63.0391 88.556 62.0194 87.2982 62.0194H86.4561C86.9925 62.0863 87.3455 62.411 87.5143 62.8234C88.2357 62.9297 88.793 63.5464 88.793 64.2969V65.1474H90.4282C92.3105 65.1474 94.0654 65.903 95.37 67.2749L95.923 67.8562L95.9686 67.8105L109.215 81.0571L109.444 81.2866H117.129C117.458 81.2866 117.783 81.3767 118.07 81.5471Z"
      fill="white"
    />
    <path
      d="M151.677 80.8745H153.476V85.1072H149.095V83.457C149.095 82.0304 150.251 80.8745 151.677 80.8745Z"
      fill="#854C56"
    />
    <path
      d="M154.398 80.5035H160.494V85.7594H154.398C152.946 85.7594 151.77 84.5831 151.77 83.1313C151.77 81.6799 152.946 80.5035 154.398 80.5035Z"
      fill="#4D8267"
    />
    <path
      d="M163.355 135.617H169.267V142.3H156.896V142.075C156.896 138.508 159.788 135.617 163.355 135.617Z"
      fill="#8EC4A0"
    />
    <path
      d="M159.57 107.611V105.689C159.57 100.27 163.963 95.8774 169.382 95.8774V135.616H159.57V112.963C159.625 112.918 159.57 107.611 159.57 107.611Z"
      fill="#C4C4C4"
    />
    <path
      d="M183.64 135.617H189.551V142.3H177.181V142.075C177.181 138.508 180.072 135.617 183.64 135.617Z"
      fill="#8EC4A0"
    />
    <path
      d="M189.446 95.8774V135.616H179.634V105.689C179.634 100.27 184.027 95.8774 189.446 95.8774Z"
      fill="#C3C3C3"
    />
    <path
      d="M167.145 95.8774H173.583V105.637H159.57V103.451C159.57 99.2683 162.962 95.8774 167.145 95.8774Z"
      fill="#C3C3C3"
    />
    <path
      d="M193.887 48.9965H185.205V65.7599H193.887V48.9965Z"
      fill="#E4D2C5"
    />
    <path
      d="M184.406 42.1986L191.591 42.3529L191.31 55.0053C191.242 58.0876 188.518 60.5292 185.228 60.4583L183.422 60.4198C180.783 60.3631 178.689 58.3135 178.743 55.842L179.129 45.083C179.19 42.3112 181.447 42.1349 184.406 42.1986Z"
      fill="#F6DDCC"
    />
    <path
      d="M195.436 44.461L193.844 58.0232L186.928 57.8382C183.711 57.752 181.144 55.2219 181.194 52.1868L181.324 44.3937L195.436 44.461Z"
      fill="#212A54"
    />
    <path
      d="M195.358 45.6952C195.358 50.1813 191.794 53.8181 187.397 53.8181C183.935 53.8181 180.836 51.1837 179.674 47.8735C179.674 46.4465 179.188 45.0824 179.188 45.0824C179.188 40.5959 183 37.5723 187.397 37.5723C191.794 37.5723 195.358 41.2091 195.358 45.6952Z"
      fill="#212A54"
    />
    <path
      d="M181.659 47.9587C182.262 47.9772 182.732 49.1768 182.709 50.638C182.687 52.0997 182.179 53.2693 181.576 53.2508C180.973 53.2323 180.502 52.0328 180.525 50.5715C180.548 49.1102 181.055 47.9406 181.659 47.9587Z"
      fill="#E4D2C5"
    />
    <path
      d="M184.14 62.0194H193.72V65.7601H181.862V64.2969C181.862 63.0391 182.882 62.0194 184.14 62.0194Z"
      fill="#7ABA9A"
    />
    <path
      d="M180.999 64.3651H194.071C196.163 64.3651 197.86 66.0613 197.86 68.1539V105.638H173.392V71.9729C173.392 67.7713 176.798 64.3651 180.999 64.3651Z"
      fill="#7FE0AF"
    />
    <path
      d="M173.392 86.9462V76.2317L177.82 74.1384L173.392 86.9462Z"
      fill="#1B1523"
    />
    <path
      d="M177.138 102.152C180.062 104.626 185.22 105.637 185.22 105.637H173.392V96.6873C173.392 96.6873 174.214 99.6779 177.138 102.152Z"
      fill="#C3C3C3"
    />
    <path
      d="M167.628 105.637H200.111C202.098 105.637 203.709 107.248 203.709 109.235C203.709 111.222 202.098 112.834 200.111 112.834H167.628C165.64 112.834 164.029 111.222 164.029 109.235C164.029 107.248 165.64 105.637 167.628 105.637Z"
      fill="#8EC5A0"
    />
    <path
      d="M167.628 105.637H181.779C183.766 105.637 185.377 107.248 185.377 109.235C185.377 111.222 183.766 112.834 181.779 112.834H167.628C165.64 112.834 164.029 111.222 164.029 109.235C164.029 107.248 165.64 105.637 167.628 105.637Z"
      fill="#A5DBB7"
    />
    <path
      d="M191.048 114.223C191.048 114.987 190.429 115.606 189.665 115.606H179.01C178.246 115.606 177.627 114.987 177.627 114.223V112.834H191.048V114.223Z"
      fill="#787878"
    />
    <path
      d="M185.311 115.607H182.977V122.901H185.311V115.607Z"
      fill="#787878"
    />
    <path d="M186.725 122.9H181.862V132.723H186.725V122.9Z" fill="#787878" />
    <path d="M175.425 95.6335H173.614V107.691H175.425V95.6335Z" fill="white" />
    <path
      d="M187.931 96.3456C188.119 97.3389 188.213 97.836 188.401 98.8297C180.63 94.7505 171.121 96.1646 164.865 102.128C164.28 102.685 163.31 102.366 163.161 101.572L163.156 101.547C163.096 101.232 163.184 100.899 163.4 100.662C169.607 93.8509 179.736 92.0433 187.931 96.3456Z"
      fill="white"
    />
    <path
      d="M184.524 106.196C184.897 107.241 185.99 107.934 187.205 107.934H201.859C203.703 107.934 205.052 106.373 204.596 104.761C202.887 98.7236 202.119 87.64 206.913 75.9223C207.728 73.9231 206.097 71.8065 203.737 71.8065H190.502C189.139 71.8065 187.903 72.5381 187.374 73.6649C184.337 80.1479 183.07 86.5211 182.806 92.0533C182.524 98.0821 183.426 103.113 184.524 106.196Z"
      fill="#EDFFE7"
    />
    <path
      d="M184.35 106.196C184.687 107.241 185.673 107.933 186.768 107.933H195.329C195.587 107.472 195.739 107.045 195.598 106.846C195.207 106.29 185.285 92.0531 185.285 92.0531H182.801C182.547 98.0819 183.361 103.113 184.35 106.196Z"
      fill="#8BD6A5"
      fillOpacity="0.4049"
    />
    <path
      d="M192.19 72.3081H204.128C206.255 72.3081 207.728 74.3983 206.992 76.3664C202.67 87.9215 203.363 98.8525 204.902 104.806C205.313 106.395 204.097 107.935 202.434 107.935H189.218C188.122 107.935 187.137 107.249 186.802 106.221C184.901 100.392 183.648 87.4969 189.372 74.144C189.85 73.0303 190.964 72.3081 192.19 72.3081Z"
      fill="#ADE9C1"
    />
    <path
      d="M193.485 136.748V139.676H191.151V136.748C191.151 135.199 188.991 133.89 186.433 133.89H181.862V131.555H186.433C190.387 131.555 193.485 133.836 193.485 136.748Z"
      fill="#787878"
    />
    <path
      d="M173.086 131.555H195.746C199.7 131.555 202.798 133.836 202.798 136.748V139.676H200.464V136.748C200.464 135.199 198.303 133.89 195.746 133.89H173.086C170.529 133.89 168.369 135.199 168.369 136.748V139.676H166.035V136.748C166.035 133.836 169.132 131.555 173.086 131.555Z"
      fill="#787878"
    />
    <path
      d="M179.774 131.555H183.178C183.822 131.555 184.345 132.078 184.345 132.723C184.345 133.367 183.822 133.89 183.178 133.89H179.774C177.217 133.89 175.057 135.199 175.057 136.748V139.676H172.723V136.748C172.723 133.836 175.82 131.555 179.774 131.555Z"
      fill="#787878"
    />
    <path
      d="M167.098 137.053C168.547 137.053 169.722 138.228 169.722 139.677C169.722 141.126 168.547 142.3 167.098 142.3C165.649 142.3 164.475 141.126 164.475 139.677C164.475 138.228 165.649 137.053 167.098 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M174.009 137.053C175.457 137.053 176.632 138.228 176.632 139.677C176.632 141.126 175.457 142.3 174.009 142.3C172.56 142.3 171.385 141.126 171.385 139.677C171.385 138.228 172.56 137.053 174.009 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M192.287 137.053C193.737 137.053 194.911 138.228 194.911 139.677C194.911 141.126 193.737 142.3 192.287 142.3C190.839 142.3 189.664 141.126 189.664 139.677C189.664 138.228 190.839 137.053 192.287 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M201.65 137.053C203.099 137.053 204.273 138.228 204.273 139.677C204.273 141.126 203.099 142.3 201.65 142.3C200.201 142.3 199.026 141.126 199.026 139.677C199.026 138.228 200.201 137.053 201.65 137.053Z"
      fill="#1B1523"
    />
    <path
      d="M180.41 71.6018L166.252 85.7598L161.354 80.8621L175.513 66.704L180.41 71.6018Z"
      fill="#7ABA9A"
    />
    <path
      d="M155.692 80.5035H166.344V85.7594H155.692C154.264 85.7594 153.106 84.602 153.106 83.1746V83.088C153.106 81.6606 154.264 80.5035 155.692 80.5035Z"
      fill="#7ABA9A"
    />
    <path
      d="M160.353 103.451V104.854H160.358L160.354 105.641L160.353 105.689V106.828L159.57 107.611V105.689L159.571 105.637H159.57V103.451C159.57 99.2683 162.962 95.8774 167.145 95.8774H170.087C170.085 95.8786 168.576 96.6327 168.502 96.6866L167.145 96.6594C163.4 96.6594 160.353 99.7063 160.353 103.451Z"
      fill="white"
    />
    <path
      d="M153.464 81.5471L153.279 81.6573H151.677C150.684 81.6573 149.877 82.4649 149.877 83.4574V84.1706H149.095V83.4574C149.095 82.0308 150.251 80.8749 151.677 80.8749H153.063C153.456 80.6408 153.913 80.5042 154.404 80.5042H161.765L175.565 66.7043L175.596 66.7357C176.982 65.2784 178.935 64.365 181.105 64.365H181.958V64.2969C181.958 63.0391 182.977 62.0194 184.235 62.0194H185.077C184.54 62.0863 184.187 62.411 184.019 62.8234C183.298 62.9297 182.74 63.5464 182.74 64.2969V65.1474H181.105C179.223 65.1474 177.468 65.903 176.163 67.2749L175.61 67.8562L175.565 67.8105L162.318 81.0571L162.089 81.2866H154.404C154.075 81.2866 153.75 81.3767 153.464 81.5471Z"
      fill="white"
    />
    <path
      d="M180.742 47.0338H179.633V51.1315H180.742V47.0338Z"
      fill="#1B1523"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.623 89.9086H241.38V85.7066H242.572V85.108H241.38H242.623V89.9086Z"
      fill="white"
    />
  </svg>
)

export default Feature5
