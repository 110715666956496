import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"

import { trackEvent } from "../../helpers/tracker"
import Button from "../ui/button"

const HeaderContainer = styled.div`
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  background-image: url("/because/mobile.jpg");
  background-size: cover;
  background-position: center;
  min-height: 124px;
  padding-bottom: 30px;
  width: 100%;
  min-height: 70vh;

  @media (min-width: 460px) {
    min-height: 360px;
  }

  @media (min-width: 736px) {
    padding-top: 60px;
    min-height: 335px;
    background-image: url("/because/tablet.jpg");
  }

  @media (min-width: 1024px) {
    padding-top: 90px;
    background-image: url("/because/desktop.jpg");
    padding-bottom: 40px;
  }

  @media (min-width: 1920px) {
    background-image: url("/because/widescreen.jpg");
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 27.65px;
  line-height: 116%;
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
  max-width: 250px;
  @media (min-width: 768px) {
    max-width: 440px;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
  max-width: 240px;
  @media (min-width: 736px) {
    max-width: 443px;
    margin-bottom: 27px;
  }
  @media (min-width: 1024px) {
    max-width: 460px;
  }
`

const Header = () => {
  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  const onClick = () => {
    trackEvent("porque-capitaria:header:create-account", {
      mc_origen: mcOrigen,
    })
  }

  return (
    <HeaderContainer>
      <Title>
        5 razones porqué los Traders de América Latina nos prefieren
      </Title>
      <Description>
        Crea tu cuenta y únete a nuestra comunidad de Traders
      </Description>
      <Button
        href={`https://onboarding.capitaria.com/sign-up${queryString}`}
        onClick={onClick}
      >
        ¡CREA TU CUENTA HORA!
      </Button>
    </HeaderContainer>
  )
}

export default Header
