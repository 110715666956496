import React from "react"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import BecauseCapitaria from "../components/because-capitaria/because-capitaria"

const PorQueCapitaria = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Porqué Capitaria" />
      <BecauseCapitaria />
    </Layout>
  </QueryStringProvider>
)

export default PorQueCapitaria
