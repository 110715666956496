import React from "react"
import styled from "@emotion/styled"

import PageLayoutExtend from "../ui/page-layout-extend"
import Header from "../because-capitaria/header"
import PreferCapitaria from "../shared/prefer-capitaria"
import DescriptionCapitaria from "../shared/description-capitaria"
import Features from "./features"
import Steps from "./steps"

const BecauseCapitariaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BecauseCapitaria = () => {
  return (
    <PageLayoutExtend header={<Header />}>
      <BecauseCapitariaContainer>
        <DescriptionCapitaria />
        <Features />
        <PreferCapitaria />
        <Steps />
      </BecauseCapitariaContainer>
    </PageLayoutExtend>
  )
}

export default BecauseCapitaria
