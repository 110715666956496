import React from "react"

const Feature4 = () => (
  <svg
    width="261"
    height="185"
    viewBox="0 0 261 185"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M251.501 154.051C265.074 169.402 252.848 180.702 245.038 184.434H5.09993C1.06055 184.434 1.86844 175.639 14.7944 166.844C25.1352 159.808 19.1031 153.251 14.7944 150.853C12.6401 151.919 7.03882 150.693 1.86844 137.26C-6.2103 120.47 13.9865 102.08 22.8732 96.4836C32.5676 86.0895 25.2968 72.4972 22.8732 61.3035C22.8732 22.9252 60.0354 35.718 69.7298 38.9161C89.9267 45.3127 91.5424 38.9161 96.3897 32.5196C114.163 -2.66056 144.054 14.9296 148.901 22.1256C157.788 34.9183 166.675 33.3192 173.946 32.5196C207.876 16.5287 222.418 40.5151 226.457 51.7088C220.802 80.4925 232.113 80.4925 236.96 88.4879C275.738 97.283 256.349 130.064 254.733 137.26C244.231 150.853 249.078 148.454 251.501 154.051Z"
      fill="#F8F8F8"
    />
    <path
      d="M240.053 130.484C240.053 128.65 238.566 127.163 236.732 127.163H167.503C165.668 127.163 164.181 128.65 164.181 130.484V177.827C164.181 179.662 165.668 181.149 167.503 181.149H232.58C236.707 181.149 240.053 177.803 240.053 173.675V130.484Z"
      fill="#5B9F7D"
      stroke="black"
      strokeWidth="1.66084"
    />
    <path
      d="M61.9511 38.3217C61.7649 46.1448 54.1124 76.1958 50.3095 90.2434H86.2822C90.9388 90.2434 92.7239 93.115 93.0343 94.5508C97.5745 79.4554 106.795 48.543 107.354 45.6559C94.3149 44.6081 64.5123 38.3217 61.9511 38.3217Z"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M90.4725 91.7572L103.278 48.9159L64.3952 42.6295L53.8014 90.2438C63.1147 90.2826 82.4631 90.3369 85.3502 90.2438C88.9824 90.0575 90.2785 91.1751 90.4725 91.7572Z"
      fill="#8AE4B8"
    />
    <path
      d="M99.0879 51.4769L88.0283 90.4764H56.9452L67.0734 46.471L99.0879 51.4769Z"
      fill="#F9F9F9"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M67.3708 92.3981C72.088 96.3758 78.8895 95.9431 82.5762 91.5711C86.2629 87.199 85.5408 80.4222 80.8236 76.4444C76.1064 72.4667 69.305 72.8994 65.6183 77.2714C61.9316 81.6435 62.6537 88.4204 67.3708 92.3981Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M80.7186 58.768C78.648 58.0214 75.8935 59.5014 74.7769 62.5981C73.6603 65.6947 74.8365 68.592 76.907 69.3386C78.9776 70.0852 81.7321 68.6052 82.8487 65.5086C83.9652 62.4119 82.7891 59.5146 80.7186 58.768Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M73.4865 79.1058C72.7 78.7131 71.9255 78.6801 71.1631 79.0068C70.4055 79.336 69.7253 79.9867 69.1224 80.959L67.6015 80.1995L67.0962 81.2114L68.5875 81.956C68.0124 83.1697 67.6989 84.3296 67.6472 85.4356L69.6059 86.4136C69.6071 85.879 69.676 85.2706 69.8128 84.5884C69.9519 83.9013 70.1247 83.314 70.331 82.8265L72.631 83.9749L72.5768 84.5661C72.4758 85.7336 72.5483 86.6341 72.7944 87.2675C73.0478 87.8984 73.5084 88.3806 74.1762 88.714C74.8933 89.0721 75.6208 89.0878 76.3586 88.7611C77.1038 88.4319 77.7754 87.7923 78.3734 86.8422L79.5086 87.409L80.0138 86.3971L78.9083 85.8452C79.4219 84.6929 79.7032 83.5601 79.7525 82.4467L77.6697 82.2741C77.6028 83.2003 77.4023 84.0844 77.0684 84.9265L74.8797 83.8336C74.9881 82.6883 75.0071 81.8458 74.9367 81.3062C74.8687 80.7617 74.7182 80.3144 74.4852 79.9643C74.2546 79.6093 73.9217 79.3231 73.4865 79.1058ZM72.2652 81.2733C72.4729 81.377 72.6067 81.5484 72.6665 81.7874C72.7264 82.0265 72.7494 82.3517 72.7356 82.763L70.8882 81.8406C71.3363 81.2278 71.7953 81.0387 72.2652 81.2733ZM75.2862 86.4909C75.0685 86.3823 74.9249 86.2183 74.8552 85.999C74.7929 85.7772 74.7712 85.4618 74.79 85.0529L76.5335 85.9235C76.1223 86.5117 75.7066 86.7009 75.2862 86.4909Z"
      fill="#363F41"
    />
    <rect
      width="54.0172"
      height="94.7629"
      rx="8.30422"
      transform="matrix(-1 0 0 1 95.3633 90.0107)"
      fill="#8B8798"
    />
    <rect
      x="-0.830422"
      y="0.830422"
      width="52.3564"
      height="93.1021"
      rx="7.47379"
      transform="matrix(-1 0 0 1 92.305 90.0107)"
      fill="white"
      stroke="black"
      strokeWidth="1.66084"
    />
    <circle
      r="0.981786"
      transform="matrix(-1 0 0 1 76.5024 95.3659)"
      stroke="#363F41"
      strokeWidth="0.830422"
    />
    <rect
      x="-0.415211"
      y="0.415211"
      width="11.2769"
      height="1.96357"
      rx="0.981786"
      transform="matrix(-1 0 0 1 72.1803 93.9689)"
      fill="#363F41"
      stroke="#4C579F"
      strokeWidth="0.830422"
    />
    <rect
      x="-0.415211"
      y="0.415211"
      width="7.55156"
      height="4.52473"
      rx="2.26237"
      transform="matrix(-1 0 0 1 70.318 175.926)"
      fill="#363F41"
      stroke="#4C579F"
      strokeWidth="0.830422"
    />
    <rect
      x="-0.830422"
      y="0.830422"
      width="45.6042"
      height="69.586"
      transform="matrix(-1 0 0 1 88.8128 100.721)"
      fill="white"
      stroke="black"
      strokeWidth="1.66084"
    />
    <path
      d="M220.278 77.3214C220.184 87.3797 226.06 117.601 229.009 131.455H184.887C181.55 118.649 174.689 92.5486 173.944 90.5928C190.359 88.4973 218.764 77.9034 220.278 77.3214Z"
      fill="#F5F5F5"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M197.015 104.683C196.35 107.402 197.873 110.002 200.265 110.587C202.657 111.171 205.208 109.566 205.872 106.847C206.536 104.129 205.013 101.528 202.621 100.944C200.229 100.36 197.679 101.965 197.015 104.683Z"
      fill="#8AE4B8"
      stroke="black"
      strokeWidth="1.66084"
    />
    <path
      d="M217.25 81.7451C217.343 88.8232 223.576 118.882 225.981 130.873H222.838C221.79 130.174 214.805 88.3809 214.223 86.1689C203.699 90.2668 188.728 93.8524 182.558 95.133L192.337 130.873H189.543C186.943 120.667 179.066 95.4124 178.6 93.2703C189.077 92.1062 215.737 82.4436 217.25 81.7451Z"
      fill="#8AE4B8"
    />
    <path
      d="M223.071 131.222C220.976 121.986 215.528 96.5765 214.689 86.0525C213.292 86.6346 185.352 95.715 183.257 95.5986C183.536 97.8338 189.931 121.482 192.337 131.222"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M183.025 86.4019C184.515 79.0444 199.401 56.7157 206.657 46.4711C181.511 32.3071 130.451 3.58326 127.378 2C122.372 18.5311 108.053 36.8085 105.491 40.1846C129.24 46.2382 179.765 84.772 183.025 86.4019Z"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M181.512 81.7452C182.443 75.5984 195.171 56.3664 201.419 47.5188C178.679 34.5965 132.431 8.28643 129.357 6.42377C125.515 17.7162 112.942 35.6443 110.614 39.2532C129.706 43.5606 178.368 79.6497 181.512 81.7452Z"
      fill="#8AE4B8"
    />
    <path
      d="M180.347 76.9718C180.067 73.2465 191.329 56.7153 196.994 48.9154C175.535 36.6141 132.22 11.9183 130.637 11.5458C127.959 19.6949 116.783 35.5276 115.735 38.2051C128.308 40.7663 177.087 74.4106 180.347 76.9718Z"
      fill="#F9F9F9"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M130.456 29.4235C129.655 31.4736 131.062 34.2662 134.128 35.4642C137.194 36.6622 140.121 35.563 140.923 33.5129C141.724 31.4628 140.317 28.6702 137.251 27.4722C134.185 26.2742 131.257 27.3734 130.456 29.4235Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M172.38 58.997C174.062 60.4159 177.159 59.984 179.281 57.4674C181.403 54.9509 181.306 51.8255 179.623 50.4066C177.941 48.9877 174.844 49.4196 172.722 51.9361C170.6 54.4526 170.697 57.5781 172.38 58.997Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M149.642 50.3568C154.36 54.3346 161.161 53.9019 164.848 49.5298C168.534 45.1578 167.812 38.3809 163.095 34.4032C158.378 30.4255 151.576 30.8581 147.89 35.2302C144.203 39.6022 144.925 46.3791 149.642 50.3568Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M151.819 41.3683C151.317 42.0901 151.174 42.8518 151.387 43.6534C151.604 44.4503 152.15 45.217 153.026 45.9533L152.056 47.3494L152.985 47.9947L153.936 46.626C155.054 47.3695 156.157 47.8464 157.244 48.0565L158.493 46.2587C157.965 46.1807 157.372 46.025 156.717 45.7917C156.057 45.5552 155.501 45.2999 155.048 45.0256L156.515 42.9146L157.092 43.0531C158.233 43.3208 159.134 43.3784 159.797 43.2259C160.457 43.0658 161.001 42.6793 161.427 42.0664C161.884 41.4081 162.004 40.6904 161.787 39.9133C161.568 39.1285 161.032 38.372 160.177 37.6437L160.901 36.6018L159.973 35.9564L159.268 36.9711C158.201 36.2973 157.12 35.8561 156.026 35.6474L155.556 37.6837C156.463 37.8831 157.309 38.2085 158.094 38.6599L156.698 40.6689C155.58 40.397 154.749 40.2571 154.205 40.2493C153.656 40.2383 153.192 40.323 152.812 40.5033C152.428 40.6804 152.097 40.9688 151.819 41.3683ZM153.789 42.8884C153.921 42.6977 154.11 42.5899 154.355 42.565C154.6 42.5401 154.925 42.5641 155.33 42.6369L154.152 44.3326C153.61 43.8011 153.489 43.3197 153.789 42.8884ZM159.386 40.6485C159.247 40.8482 159.064 40.9668 158.837 41.0043C158.609 41.034 158.294 41.0102 157.892 40.9329L159.004 39.3326C159.527 39.8239 159.654 40.2626 159.386 40.6485Z"
      fill="#363F41"
    />
    <path
      d="M195.192 127.82C195.836 133.957 201.039 138.359 206.727 137.762C212.415 137.166 216.591 131.78 215.947 125.643C215.303 119.507 210.1 115.105 204.413 115.701C198.725 116.298 194.549 121.684 195.192 127.82Z"
      fill="#8AE4B8"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M202.992 122.849C202.13 123.022 201.498 123.471 201.097 124.197C200.701 124.922 200.563 125.853 200.684 126.991L199.017 127.325L199.239 128.434L200.874 128.106C201.165 129.417 201.629 130.526 202.267 131.43L204.414 131C204.087 130.577 203.768 130.055 203.457 129.432C203.145 128.805 202.921 128.235 202.785 127.723L205.305 127.218L205.625 127.718C206.262 128.702 206.872 129.368 207.455 129.717C208.043 130.06 208.702 130.158 209.434 130.011C210.22 129.853 210.804 129.419 211.186 128.709C211.572 127.991 211.71 127.074 211.598 125.957L212.843 125.708L212.62 124.599L211.409 124.842C211.107 123.617 210.634 122.55 209.989 121.64L208.239 122.783C208.755 123.555 209.139 124.376 209.392 125.246L206.994 125.726C206.376 124.756 205.874 124.079 205.487 123.696C205.099 123.308 204.706 123.047 204.307 122.914C203.907 122.775 203.469 122.754 202.992 122.849ZM203.358 125.31C203.586 125.264 203.797 125.317 203.991 125.469C204.184 125.621 204.402 125.864 204.644 126.197L202.619 126.603C202.597 125.844 202.843 125.413 203.358 125.31ZM208.946 127.575C208.707 127.622 208.493 127.581 208.303 127.451C208.118 127.314 207.907 127.078 207.671 126.744L209.582 126.361C209.619 127.078 209.406 127.482 208.946 127.575Z"
      fill="#363F41"
    />
    <path
      d="M239.82 131.587H172.392C170.557 131.587 169.07 133.074 169.07 134.908V180.389C169.07 182.223 170.557 183.71 172.392 183.71H232.346C236.474 183.71 239.82 180.364 239.82 176.237V131.587Z"
      fill="#7FE0AF"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <path
      d="M180.316 152.41H164.85C164.086 152.41 163.466 153.029 163.466 153.794V163.017C163.466 163.781 164.086 164.401 164.85 164.401H180.356C180.79 164.401 181.199 164.197 181.46 163.851L184.752 159.491C185.111 159.016 185.126 158.365 184.79 157.875L181.457 153.012C181.199 152.635 180.772 152.41 180.316 152.41Z"
      fill="#5B9F7D"
      stroke="#363F41"
      strokeWidth="1.66084"
    />
    <circle
      r="3.36057"
      transform="matrix(-1 0 0 1 175.69 158.463)"
      fill="#363F41"
      stroke="black"
      strokeWidth="1.66084"
    />
    <path
      d="M60.0472 122.466L58.62 117.815H58.5835C58.6348 118.762 58.6605 119.393 58.6605 119.709V122.466H57.5373V116.538H59.2484L60.6514 121.071H60.6757L62.1638 116.538H63.875V122.466H62.7031V119.66C62.7031 119.528 62.7045 119.375 62.7072 119.202C62.7126 119.029 62.7315 118.57 62.764 117.824H62.7275L61.1988 122.466H60.0472ZM67.6581 122.466H66.4011V117.584H64.7913V116.538H69.2678V117.584H67.6581V122.466ZM74.0444 121.238H73.3307V122.466H72.1062V121.238H69.576V120.366L72.1751 116.538H73.3307V120.265H74.0444V121.238ZM72.1062 120.265V119.259C72.1062 119.091 72.113 118.848 72.1265 118.529C72.14 118.21 72.1508 118.025 72.1589 117.974H72.1265C72.0264 118.195 71.9062 118.412 71.7656 118.622L70.6789 120.265H72.1062Z"
      fill="#BFBFBF"
    />
    <rect
      width="31.4324"
      height="6.51932"
      rx="2.76807"
      transform="matrix(-1 0 0 1 82.5566 128.894)"
      fill="#E6E6E6"
    />
    <rect
      width="31.4324"
      height="6.51932"
      rx="2.76807"
      transform="matrix(-1 0 0 1 82.791 139.837)"
      fill="#E6E6E6"
    />
    <path
      d="M228.931 159.41L234.924 136.772H238.918V173.392C238.918 181.915 232.26 183.158 228.931 182.714H194.975L209.623 175.39L213.617 168.732L228.931 159.41Z"
      fill="#78CFA4"
    />
  </svg>
)

export default Feature4
