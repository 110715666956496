import React from "react"

const Feature2 = () => (
  <svg
    width="238"
    height="192"
    viewBox="0 0 238 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.9124 42.785C56.5853 17.9089 83.6617 0 115.551 0C138.876 0 159.626 9.58101 172.874 24.4615C209.446 27.6464 238 54.4725 238 87.0943C238 120.021 208.91 147.044 171.846 149.81C156.388 174.599 126.162 191.435 91.4059 191.435C40.9238 191.435 0 155.916 0 112.102C0 82.3058 18.9255 56.3465 46.9124 42.785Z"
      fill="#F8F8F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.152 161.312C156.344 161.312 180.819 132.062 180.819 95.9801C180.819 59.8983 156.344 30.6481 126.152 30.6481C95.9598 30.6481 71.4844 59.8983 71.4844 95.9801C71.4844 132.062 95.9598 161.312 126.152 161.312ZM126.151 144.269C142.382 144.269 155.539 122.649 155.539 95.98C155.539 69.3108 142.382 47.6912 126.151 47.6912C109.921 47.6912 96.7637 69.3108 96.7637 95.98C96.7637 122.649 109.921 144.269 126.151 144.269Z"
      fill="#5B9F7D"
    />
    <mask id="feature2" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.936 161.312C148.128 161.312 172.603 132.062 172.603 95.9801C172.603 59.8983 148.128 30.6481 117.936 30.6481C87.744 30.6481 63.2686 59.8983 63.2686 95.9801C63.2686 132.062 87.744 161.312 117.936 161.312ZM117.936 144.269C134.166 144.269 147.324 122.649 147.324 95.98C147.324 69.3108 134.166 47.6912 117.936 47.6912C101.705 47.6912 88.5481 69.3108 88.5481 95.98C88.5481 122.649 101.705 144.269 117.936 144.269Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.936 161.312C148.128 161.312 172.603 132.062 172.603 95.9801C172.603 59.8983 148.128 30.6481 117.936 30.6481C87.744 30.6481 63.2686 59.8983 63.2686 95.9801C63.2686 132.062 87.744 161.312 117.936 161.312ZM117.936 144.269C134.166 144.269 147.324 122.649 147.324 95.98C147.324 69.3108 134.166 47.6912 117.936 47.6912C101.705 47.6912 88.5481 69.3108 88.5481 95.98C88.5481 122.649 101.705 144.269 117.936 144.269Z"
      fill="#7FE0AF"
    />
    <path
      d="M171.924 95.9801C171.924 131.805 147.645 160.633 117.936 160.633V161.991C148.611 161.991 173.282 132.319 173.282 95.9801H171.924ZM117.936 31.3271C147.645 31.3271 171.924 60.1549 171.924 95.9801H173.282C173.282 59.6416 148.611 29.9692 117.936 29.9692V31.3271ZM63.9475 95.9801C63.9475 60.1549 88.2273 31.3271 117.936 31.3271V29.9692C87.2607 29.9692 62.5896 59.6416 62.5896 95.9801H63.9475ZM117.936 160.633C88.2273 160.633 63.9475 131.805 63.9475 95.9801H62.5896C62.5896 132.319 87.2607 161.991 117.936 161.991V160.633ZM146.645 95.98C146.645 109.211 143.379 121.157 138.136 129.772C132.885 138.4 125.724 143.59 117.936 143.59V144.948C126.378 144.948 133.911 139.328 139.296 130.478C144.689 121.617 148.003 109.418 148.003 95.98H146.645ZM117.936 48.3702C125.724 48.3702 132.885 53.5599 138.136 62.1876C143.379 70.8033 146.645 82.749 146.645 95.98H148.003C148.003 82.5418 144.689 70.3431 139.296 61.4817C133.911 52.6323 126.378 47.0122 117.936 47.0122V48.3702ZM89.2271 95.98C89.2271 82.749 92.4923 70.8033 97.7356 62.1876C102.986 53.5599 110.148 48.3702 117.936 48.3702V47.0122C109.493 47.0122 101.961 52.6323 96.5756 61.4817C91.1826 70.3431 87.8691 82.5418 87.8691 95.98H89.2271ZM117.936 143.59C110.148 143.59 102.986 138.4 97.7356 129.772C92.4923 121.157 89.2271 109.211 89.2271 95.98H87.8691C87.8691 109.418 91.1826 121.617 96.5756 130.478C101.961 139.328 109.493 144.948 117.936 144.948V143.59Z"
      fill="black"
      mask="url(#feature2)"
    />
    <path
      d="M80.1082 77.4912C78.8488 87.0596 70.8066 94.8185 65.0007 94.0544C59.1948 93.2902 55.5092 84.914 56.7685 75.3456C58.0279 65.7772 63.7554 58.6399 69.5613 59.404C75.3672 60.1682 81.3676 67.9228 80.1082 77.4912Z"
      fill="#EBEFF9"
    />
    <path
      d="M51.6415 92.6143C45.5087 92.3348 39.5998 90.9653 33.9151 88.5058V74.5874C37.1635 76.2364 40.692 77.6199 44.5005 78.7378C48.3091 79.8278 51.6555 80.3728 54.5399 80.3728C56.9202 80.3728 58.6565 80.0933 59.7486 79.5343C60.8688 78.9474 61.4289 77.9832 61.4289 76.6417C61.4289 75.7194 61.0228 74.9368 60.2107 74.294C59.4266 73.6512 58.0964 72.9245 56.2201 72.114C54.3719 71.3035 51.8655 70.3253 48.7011 69.1794C45.1446 67.8658 42.2742 66.3985 40.0899 64.7775C37.9056 63.1285 36.3094 61.2979 35.3013 59.2856C34.3211 57.2454 33.8311 54.716 33.8311 51.6976C33.8311 47.0861 35.3153 43.4807 38.2837 40.8815C41.2521 38.2823 45.7047 36.6752 51.6415 36.0604V30.317H60.3367V35.9765C65.9095 36.284 71.5243 37.6534 77.181 40.085L72.1823 52.033C66.3295 49.4058 61.0508 48.0922 56.3462 48.0922C54.1059 48.0922 52.5236 48.3717 51.5995 48.9307C50.7034 49.4617 50.2553 50.2582 50.2553 51.3203C50.2553 52.2426 50.5774 53.0112 51.2215 53.626C51.8935 54.213 53.0417 54.8837 54.6659 55.6383C56.2901 56.365 58.5445 57.2174 61.4289 58.1956C67.2817 60.2638 71.4822 62.6255 74.0306 65.2806C76.5789 67.9077 77.8531 71.3035 77.8531 75.4678C77.8531 80.1632 76.3549 83.9782 73.3585 86.9128C70.3901 89.8473 66.0495 91.664 60.3367 92.3627V100.454H51.6415V92.6143Z"
      fill="#5B9F7D"
    />
    <path
      d="M27.9529 88.5058V88.6914L28.1232 88.7651C33.7558 91.202 39.6083 92.5751 45.6793 92.8835V100.454V100.736H45.9618H54.657H54.9395V100.454V92.612C60.5694 91.8866 64.8932 90.0634 67.8765 87.1146C70.9339 84.1203 72.456 80.2292 72.456 75.4679C72.456 71.2413 71.1605 67.7716 68.5542 65.0844C65.9611 62.3829 61.7123 60.0032 55.8433 57.9293L55.8399 57.9281C52.96 56.9514 50.7159 56.1025 49.1035 55.3813C47.4893 54.6312 46.3735 53.9758 45.7324 53.4174C45.1493 52.8587 44.8582 52.1663 44.8582 51.3203C44.8582 50.3557 45.2556 49.6527 46.0639 49.1738L46.066 49.1725C46.9193 48.6564 48.4332 48.3748 50.6665 48.3748C55.3195 48.3748 60.5581 49.6743 66.387 52.2907L66.6514 52.4095L66.7633 52.142L71.762 40.194L71.87 39.9359L71.6129 39.8254C66.019 37.4209 60.4608 36.0479 54.9395 35.71V30.317V30.0345H54.657H45.9618H45.6793V30.317V35.8063C39.8377 36.4436 35.4045 38.0538 32.4179 40.669C29.3776 43.3311 27.8688 47.0198 27.8688 51.6976C27.8688 54.7449 28.3634 57.319 29.3669 59.408L29.3669 59.408L29.369 59.4122C30.4004 61.4709 32.0284 63.3334 34.24 65.003L34.2419 65.0044C36.4543 66.6463 39.351 68.1249 42.9235 69.4445L42.9252 69.4451C46.0871 70.59 48.5869 71.5658 50.427 72.3728L50.4284 72.3734C52.2998 73.1818 53.5997 73.8958 54.3519 74.5125L54.3519 74.5125L54.3557 74.5155C55.1095 75.1123 55.4667 75.8171 55.4667 76.6417C55.4667 77.2727 55.3352 77.7981 55.0848 78.2291C54.8352 78.6589 54.4577 79.0115 53.9389 79.2836C52.9087 79.8103 51.2293 80.0903 48.8602 80.0903C46.01 80.0903 42.691 79.5515 38.8993 78.4664C35.1064 77.353 31.5946 75.9758 28.3633 74.3356L27.9529 74.1272V74.5875V88.5058Z"
      fill="#7FE0AF"
      stroke="black"
      strokeWidth="0.565044"
    />
  </svg>
)

export default Feature2
